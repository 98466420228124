import React, {Component} from 'react';

import {Button, Modal} from 'react-bootstrap';
import {observer} from 'mobx-react';
import {computed, makeObservable, observable, values, action, toJS, reaction} from 'mobx';

class AlertDialogStore {

    constructor() {
        makeObservable(this);
    }

    @observable
    show = false;

    @observable
    title = '';

    @observable
    summary = '';

    @observable
    content = '';

    @observable
    onExit = undefined;

    @action.bound
    clear() {
        this.title = '';
        this.summary = '';
        this.content = '';

        this.onExit = undefined;
    }

    @action.bound
    open(title, summary, content = '', onExit) {
        this.clear();
        this.title = title;
        this.summary = summary;
        this.content = content;
        this.show = true;
        this.onExit = onExit;
    }


    @action.bound
    close() {
        this.show = false;
    }
}

const alertDialogStore = new AlertDialogStore();

@observer
class AlertDialog extends Component {
    close() {
        alertDialogStore.close();
    }

    render() {
        return (
            <Modal
                centered
                show={alertDialogStore.show}
                onExit={!!alertDialogStore.onExit ? () => alertDialogStore.onExit() : undefined}
                onHide={this.close}
            >
                <Modal.Header>
                    <Modal.Title className="text-left">
                        {alertDialogStore.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p className="text-center">{alertDialogStore.content}</p>
                    <div className="text-right">
                        <Button onClick={() => this.close()} variant="primary">
                            확인
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export {AlertDialog, alertDialogStore};
