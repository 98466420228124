import React from 'react';
import Styles from "./ChannelList.module.scss"
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {Channel} from "./Channel.js";
import {NoChannel} from "./NoChannel.js";
import {ChannelStore} from "../../store/ChannelStore.js";
import Pagination from "../dataGrid/Pagination.js";

@observer
class ChannelList extends React.Component{

    componentDidMount() {
        let {store} = this.props;
    }
    render() {
        let {store, onChannelClick, header, footer, isAgent = false, renderLabel, onCloseCallback, activeChannel} = this.props;
        return(
            <div className={Styles.Channel}>
               {header && header}
                <div className={Styles.Scroll}>
                    {
                        store.channels.length > 0 ?
                            store.channels.map(channel=>
                                <Channel key={channel.id} channel={channel} store={store} onChannelClick={onChannelClick} isAgent={isAgent} renderLabel={renderLabel} onCloseCallback={onCloseCallback} activeChannel={activeChannel}/>
                            )
                            :
                            <NoChannel/>
                    }
                </div>
                <div className={`d-flex justify-content-center`}>
                    {

                        !store.isNoPaging && store.count > 0 &&
                        <Pagination store={store}/>
                    }
                </div>
                {footer}
            </div>
        )
    }
}


ChannelList.propType = {
    store: PropTypes.instanceOf(ChannelStore),
    onChannelClick: PropTypes.func,
    header: PropTypes.element
}

export {ChannelList};
