import React from 'react';
import Styles from './Content.module.scss'
import IncomingMessage from './message/IncomingMessage.js';
import OutgoingMessage from './message/OutgoingMessage.js';
import {AdminMessage} from './message/AdminMessage.js';
import {DateLine} from './message/DateLine.js';
import moment from 'moment';
import {observer} from 'mobx-react';
import {Spinner, Image} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import { MESSAGE_TYPE} from "../../const/ChatConst.js";
import {ScrollToBottomButton} from "./ScrollToBottomButton.js";
import TypingUser from "./message/TypingUser.js";
import {GreetingMessage} from "./message/custom/GreetingMessage.js";

@observer
class Content extends React.Component {

    componentDidMount() {
        let {store} = this.props;
        store.debounceScrollToBottom();
    }

    renderMessage(message, store, isSameUser){
        switch (message.type) {
            case MESSAGE_TYPE.ADMIN: return <AdminMessage message={message} key={message.id}/>;
            case MESSAGE_TYPE.GREETING: return <GreetingMessage message={message} key={message.id} store={store}/>;
            default: return(
                store?.sender?.id == message?.sender?.id ?
                    <OutgoingMessage message={message} key={message.id} messageKey={message.id} store={store}/>
                    :
                    <IncomingMessage message={message} key={message.id} messageKey={message.id} isSameUser={isSameUser} store={store}/>
            )

        }
    }

    render() {
        let {store, contentClassName} = this.props;
        let {messages} = store;
        return (
            <div className={`${Styles.ContentContainer}`}>
                <div className={`${Styles.Content} ${contentClassName ? contentClassName : ''}`} ref={store.scrollRef} onScroll={store.onScrollHandler}>
                    <div className={Styles.ScrollContainer}>
                        <InfiniteScroll
                            key={`infinite`}
                            pageStart={0}
                            loadMore={()=>{
                                store.loadMore()
                                    .then(r=>{
                                        if(store.isInitialLoad){
                                            store.debounceScrollToBottom();
                                            store.isInitialLoad = false;
                                        }
                                    })
                            }}
                            hasMore={store.hasMore}
                            isReverse
                            initialLoad
                            useWindow={false}
                            threshold={5}
                            getScrollParent = { ( )  =>  store.scrollRef.current }
                            loader={
                                <div className={Styles.Loader} key={'loader'}>
                                    <Spinner animation="border" variant="secondary" size="sm"/>
                                </div>
                            }
                        >
                            {
                                messages && messages.map((message,idx)=>{

                                    let previousMessage = idx != 0 ? messages[idx-1] : undefined;
                                    let isSameDay = moment(message?.createdAt).isSame(previousMessage?.createdAt, 'day') && previousMessage;
                                    let isSameUser = message?.sender?.id == previousMessage?.sender?.id;
                                    return (
                                        <div key={`${message.id}_message`} id={`${message.id}_message`}>
                                            {
                                                (!isSameDay) &&
                                                    <DateLine createdAt={message.createdAt} key={`date_${message.id}`}/>
                                            }
                                            {
                                                this.renderMessage(message, store, isSameUser)
                                            }
                                        </div>
                                    );
                                })
                            }
                            {
                                store?.typingUser?.size > 0 &&
                                    store.typingUsers.filter(user=> user?.channelId == store?.channelId).map(user=>
                                        <TypingUser user={user} key={user.id}/>
                                    )
                            }
                            <div ref={store.messageEndRef} style={{height:`1rem`}} key={'message-end'}/>
                        </InfiniteScroll>
                    </div>

                </div>
                <ScrollToBottomButton store={store}/>
            </div>
        )
    }
}

export {Content};
