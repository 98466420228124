import React from 'react';
import Styles from './AdminMessage.module.scss'
import moment from "moment";

class AdminMessage extends React.Component {
    render() {
        let {message} = this.props;
        let {content, createdAt} = message;
        let {type, value} = content;
        return (
            <div className={`d-flex justify-content-center`}>
                <div className={Styles.Message}>
                    {value}&nbsp;&nbsp;-&nbsp;&nbsp;{moment(createdAt).format('LT')}
                </div>
            </div>
        )
    }
}

export {AdminMessage};