import {action, computed, makeObservable, observable} from 'mobx';
import {MENU_GROUP_RE} from '../const/AppConst';
import Cookies from 'js-cookie';
import * as moment from 'moment';
import axios from 'axios';
import {toast} from 'react-toastify';
import {navigate} from '../helper/HistoryHelper';


const AUTH = 'authKey';
const ID = 'id';
const EMAIL = 'email';
const IS_SAVE = 'save';
const USER_ID = 'username';
const USER_NAME = 'name';
const IS_ADMIN = 'admin';

export const CookieKeys = {AUTH, ID, USER_ID, USER_NAME, IS_ADMIN, EMAIL, IS_SAVE};
axios.interceptors.response.use((response) => response, (error) => {
    let response = error.response;
    if (!response || !response.data) {
        toast.error(error);
        throw error;
    }

    toast.error(response.data.message);

    if (response.status === 401) {
        appStore.isLogin = false;
        navigate('/login');
    }
    throw error;
});

class EchoResponder {

    fireAndForget(payload) {
        console.log('fire-and-forget', payload);
    }
}

/**
 * 앱 전반에 관련된 상태
 */
class AppStore {

    eventTrackFn;
    failedPath = '/';
    @observable
    now = moment();
    @observable
    isLogin = false;
    @observable
    userId = '';
    @observable
    userEmail = '';
    @observable
    name = Cookies.get(CookieKeys.USER_NAME);
    @observable
    permissions = [{id: '/login'}];
    @observable
    isAdmin = false;
    @observable
    isSuper = false;
    @observable
    currentPath = '/';
    @observable
    previousPath = '/';
    @observable
    sideBarOpen = false;
    @observable
    isSessionChecked = false;
    @observable
    username = '';
    @observable
    type = '';
    @observable
    agent = {};
    @observable
    chatUser = {};
    @observable
    isUpdatingSession = false;

    constructor() {
        makeObservable(this);
        setInterval(
            () => this.now = moment()
            , 1000
        );
    }

    @computed
    get currentMenuGroupActive() {
        let matched = appStore.currentPath.match(MENU_GROUP_RE);
        if (matched && matched.length > 1)
            return matched[1];
        else
            return 'people';
    }

    @action.bound
    updateSession({id, name, email, type, username, agent, chatUser}) {
        this.userId = id;
        this.userEmail = email;
        this.name = name;
        this.username = username;
        this.type = type;
        Cookies.set(CookieKeys.ID, id);
        Cookies.set(CookieKeys.USER_NAME, name);
        this.isLogin = true;
        this.agent = agent;
        this.chatUser = chatUser;

        if (type === 'ADMIN' || type === 'SUPER') {
            this.isAdmin = true;
            if (type === 'SUPER')
                this.isSuper = true;
            else
                this.isSuper = false;
        } else {
            this.isAdmin = false;
        }
    }

    @action.bound
    clearSession() {
        this.userId = '';
        this.userEmail = '';
        this.name = '';
        this.userProfileUrl = '';
        Cookies.remove(CookieKeys.ID);
        Cookies.remove(CookieKeys.USER_NAME);
        this.eventTrackFn = undefined;
        this.isLogin = false;
    }

    /**
     * 로그인을 요청한다.
     * @param form 로그인폼
     * @param success 성공콜백
     * @paraam fail 실패콜백
     */
    @action.bound
    async fetchLogin({form, success, fail, beforeSend, complete}) {
        const self = this;
        beforeSend && beforeSend();
        this.isUpdatingSession = true;
        axios.post('/user/login', form, {withCredentials: true}).then(response => {
            const data = response?.data;
            const {id, name, type, username, agent, chatUser} = data;
            this.updateSession({
                id,
                name,
                type,
                username,
                agent,
                chatUser
            });
            success && success(data);
            this.isUpdatingSession = false;
            navigate('/login');
        }).catch(function (error) {
            fail && fail(error);
            self.isUpdatingSession = false;
        }).finally(() => {
            complete && complete();
        });
    }

    /**
     * 로그아웃
     */
    @action.bound
    fetchLogout() {
        axios.post('/user/logout',
            {
                user: {id: appStore.userId}
            }
        ).then(() => {
            window.localStorage.clear('cookie');
            this.clearSession();
            navigate('/login');
        });
    }

    /**
     * 로그인 세션이 쿠키에 남아있을때 세션정보를 재 로드한다.
     */
    @action.bound
    async fetchSessionInfo() {
        try {
            /*let store = this;
            store.isUpdatingSession = true;
            const response = await axios.post('/user/session-touch').finally( r => {
                store.isUpdatingSession = false;
            });
            if(!response.data){
                this.clearSession()
            } else {
                let {id, name, type, username, agent, chatUser} = response?.data;
                this.updateSession({
                    id,
                    name,
                    type,
                    username,
                    agent,
                    chatUser
                })
            }*/

        } catch (e) {
            this.clearSession();
        } finally {
            this.isSessionChecked = true;
        }
    }



}

const appStore = new AppStore();
window.appStore = appStore;
export default appStore;
