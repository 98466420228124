import {computed, makeObservable, observable, values, action, toJS} from 'mobx';
import React from 'react';
import CommonHelper from "../helper/CommonHelper.js";
import {CHAT_EVENT_TYPE} from "../const/ChatConst.js";
import {debounce} from "lodash";

class TicketStore {

    constructor() {
        makeObservable(this);
    }

    @computed
    get chatStore(){
        return this.rsocketStore.chatStore || undefined;
    }

    stream;

    connect = () => {
        let store = this;
        const r = store.requestStream();
        store.stream = r;
    };

    requestStream = () => {
        return this.rsocketStore.requestTicketStream(
            (payload, isComplete) => {
                let store = this;
                let chatEvent = CommonHelper.deserialize(payload.data);
                let {type, ticket} = chatEvent;
                if (type === CHAT_EVENT_TYPE.ENTER || type === CHAT_EVENT_TYPE.ASSIGNMENT) {
                    store.onAssignmentHandler && store.onAssignmentHandler(ticket);
                    let ticketMap = store.ticketMap;
                    ticketMap.set(ticket.id, ticket);
                }else if (type === CHAT_EVENT_TYPE.UNASSIGNED) {
                    store.unAssigned(ticket)
                } else if (type === CHAT_EVENT_TYPE.CLOSE
                    || type === CHAT_EVENT_TYPE.ACTIVE
                    || type === CHAT_EVENT_TYPE.IDLE
                    || type === CHAT_EVENT_TYPE.PENDING
                ) {
                    if (type === CHAT_EVENT_TYPE.CLOSE) {
                        store.onClosedTicket(ticket);
                    }
                }else if(type === CHAT_EVENT_TYPE.TYPING_START || type === CHAT_EVENT_TYPE.TYPING_END){
                    if(store.chatStore){
                        store.chatStore?.onUserTypingHandler(type, chatEvent);
                    }
                }else if(type === CHAT_EVENT_TYPE.NOT_CONFIRM_COUNT || type === CHAT_EVENT_TYPE.CONFIRM_MESSAGE){
                    if(store.chatStore){
                        store.chatStore?.onMessageConfirmHandler(type, chatEvent);
                    }
                }else if(type === CHAT_EVENT_TYPE.ASSIGNED){
                    if(store.chatStore){
                        store.chatStore?.updateAssigned();
                    }
                }

            }
        );
    }

    onCloseHandler;

    onUnAssignedFn;

    setOnCloseHandler(handler) {
        this.onCloseHandler = handler
    }

    setUnAssignedFn(unAssignedFn) {
        this.onUnAssignedFn = unAssignedFn;
    }

    load = async () => {
        let store = this;
        const r = await store.rsocketStore.requestChatApi(`/ticket/joined`, {})
        if(r.data == "" || r.data == undefined){
            store.ticketMap = []
        }else{
            let tickets = r.data;
            store.ticketMap.replace(tickets.map(ticket => [ticket.id, ticket]));
        }
    }

    loadHistory = (userId) => {
        let store = this;
        store.rsocketStore.requestChatApi(`/ticket/history`, {userId})
            .then(r => {
                let tickets = r.data;
                store.ticketMap.replace(tickets.map(ticket => [ticket.id, ticket]));
            })
    }

    async setRSocketStore(rsocketStore) {
        this.rsocketStore = rsocketStore;
    }

    @computed
    get rsocket() {
        return this.rsocketStore.rsocket;
    }

    @computed
    get sender() {
        return this.rsocketStore?.sender;
    }

    rsocketStore;

    @observable
    ticketMap = new Map();

    @observable
    recommendMap = new Map();

    @observable
    greetingMessage;

    setGreetingMessage(greetingMessage) {
        this.greetingMessage = greetingMessage
    }

    @computed
    get tickets() {
        return values(this.ticketMap);
    }

    @computed
    get activeTickets() {
        return this.tickets.filter(({status}) => status === 'ACTIVE' || status === 'IDLE')
    }

    createChannel = async ({properties, greetingMessage, team, scenario, companyId}) => {
        if (!this.sender?.id) {
            alert('채팅 계정이 없습니다.');
            return;
        }

        let {data: channel} = await this.rsocketStore.requestChatApi('/ticket/create/bot',
            {
                channel: {
                    customerId: this.sender?.id,
                },
                team,
                scenario,
                companyId,
                properties,
                greetingMessage,
                creator: this.sender
            });
        return channel;
    }

    debounceFirstEnter = debounce((channel) => {
        let store = this;
        store.firstEnterTicket(channel)
    }, 1000, {leading:true})

    enterChannel = async (channel) => {
        let store = this;
        if (store.ticketMap.has(channel.id)) {
            let ticket = store.ticketMap.get(channel.id)
            if (!ticket?.isConfirm) {
                store.debounceFirstEnter(channel);
            }
        } else {
            if (!channel?.isConfirm) {
                store.debounceFirstEnter(channel);
            }
        }
        return channel;
    }

    @observable
    isOpenDropDown = false;

    @observable
    selectedChannelId;

    @observable
    x;

    @observable
    y;

    openDropDown(channel, x, y) {
        let store = this;
        store.x = x;
        store.y = y;
        store.isOpenDropDown = true;
        store.selectedChannelId = channel.id;
    }

    async leave(channelId) {
        let store = this;

        store.rsocket.fireAndForget(
            {
                data: CommonHelper.serialize({sender: store.sender, channelId}),
                metadata: CommonHelper.generateMetadata(`channel/leave`)
            },
            {
                onComplete: (a) => {
                },
                onError: (error) => {
                }
            }
        );
        let result = await store.rsocketStore.requestChatApi(`/channel/leave/${channelId}`, {})
        store.ticketMap.delete(channelId);
        return channelId;
    }

    async close(channelId) {
        let store = this;
        let result = await store.rsocketStore.requestChatApi(`/ticket/close`, {id: channelId})
        store.ticketMap.delete(channelId);
        return channelId;
    }

    async unAssigned(ticket) {
        let store = this;
        let ticketId = ticket.id
        if (store.ticketMap.has(ticketId)) {
            let originTicket = store.ticketMap.get(ticketId);
            store.ticketMap.set(ticketId, {
                ...originTicket,
                isFinished: true,
                finishedType: CHAT_EVENT_TYPE.UNASSIGNED
            });
            store.onUnAssignedFn && store.onUnAssignedFn(ticket);
        }
        if(store.chatStore){
            store.chatStore.unAssigned(ticket);
        }
        return ticketId;
    }

    onClosedTicket(channel) {
        let store = this;
        let channelId = channel.id;
        if (store.ticketMap.has(channelId)) {
            let originTicket = store.ticketMap.get(channelId);
            store.ticketMap.set(channelId, {
                ...originTicket,
                isFinished: true,
                finishedType: CHAT_EVENT_TYPE.CLOSE
            });
            store.onCloseHandler && store.onCloseHandler(channelId);
        }
        if(store.rsocketStore.chatStore){
            store.rsocketStore.chatStore.closed(channel);
        }
    }

    async firstEnterTicket(channel) {
        let store = this;
        let result = await store.rsocketStore.requestChatApi('/ticket/first/enter',
            channel
        );
        let origin = store.ticketMap.get(channel?.id);
        store.ticketMap.set(channel?.id, {...origin, isConfirm: true})
        return channel;
    }

    async initAssignment() {
        let store = this;
        let result = await store.rsocketStore.requestChatApi('/ticket/init/assignment',
            store.sender
        );
        return result;
    }

    async allTransfer() {
        let store = this;
        let result = await store.rsocketStore.requestChatApi('/ticket/all/transfer',
            store.sender
        );
        return result;
    }


    deleteTicket(ticketId) {
        let store = this;
        store.ticketMap.delete(ticketId)
    }

    onAssignmentHandler

    setOnAssignmentHandler(onAssignmentHandler){
        this.onAssignmentHandler = onAssignmentHandler;
    }

    clear(){
        let store = this;
        store.ticketMap.clear();
    }
}

export {TicketStore};
