import React, {Component, Fragment} from 'react';
import PropTypes, {oneOfType} from 'prop-types';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {computed, entries, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {FaCheck, FaFilter} from 'react-icons/fa';


class ColumnPanelStore {

    columns = [];

    jsonKeyLabelMap;

    localStorageKey;

    /**
     * 컬럼 표시/미표시 맵
     * @type {Map<any, any>}
     */
    @observable
    columnMap = new Map();


    constructor(columns, localStorageKey) {
        this.columns = columns;
        this.jsonKeyLabelMap = new Map(
            columns.map(column => ([
                JSON.stringify(column.props.id),
                column.props.name
            ]))
        );

        /*if(localStorageKey) {
            this.localStorageKey = localStorageKey;
            let columnFilter = ls.get('columnFilter');
            if(!columnFilter) {
                ls.set('columnFilter', {});
                columnFilter = {};
            }
            found = columnFilter[localStorageKey];
        }*/


        this.columnMap = new Map(
            columns.map(column => {
                const id = JSON.stringify(column.props.id);
                return [
                    id,
                    /*found ? found[id] : true*/
                    !column.props.initHide
                ]
            })
        );

        makeObservable(this);

    }

    /**
     * 보여지는 컬럼목록.
     */
    @computed
    get visibleColumns() {
        return this.columns.filter(
            column => {
                return this.columnMap.get(JSON.stringify(column.props.id));
            }
        );
    }

    saveToLocalStorage() {

        /*if(this.localStorageKey) {
            let columnFilter = ls.get('columnFilter');
            if(!columnFilter) {
                columnFilter = {};
            }
            columnFilter[this.localStorageKey] = CommonHelper.mapToObject(toJS(this.columnMap))

            ls.set('columnFilter', columnFilter)
        }*/
    }

    @computed
    get isCheckedAll() {
        return entries(this.columnMap).every(([, v]) => v);
    }

}


@observer
class ColumnPanelCheckBoxGroup extends Component {

    checkAll() {
        let {columnPanelStore} = this.props;
        let columnMap = columnPanelStore.columnMap;

        if (columnPanelStore.isCheckedAll) {
            columnMap.replace(entries(columnMap).map(([k,]) => [k, false]));
        } else {
            columnMap.replace(entries(columnMap).map(([k,]) => [k, true]));
        }
        columnPanelStore.saveToLocalStorage();
    }

    render() {
        const {columnPanelStore} = this.props;
        return (

                <DropdownButton id="dropdown-custom-components" title={<><FaFilter className={`${columnPanelStore.isCheckedAll ? '' : 'text-primary'}`}/> 컬럼</>}
                                autoClose="outside"
                                size="sm"
                                variant="outline-secondary">
                <Dropdown.Item onClick={this.checkAll.bind(this)} eventKey="all">
                    <span style={{width: 12, display: 'inline-block'}}>
                        {
                            columnPanelStore.isCheckedAll &&
                            <FaCheck className="text-success"/>
                        }
                    </span>

                    <span className="ms-2">
                        전체선택
                    </span>
                </Dropdown.Item>
                {
                    entries(columnPanelStore.columnMap).map( ([names, ], idx) =>
                        <ColumnPanelCheckBox key={`${names}-${idx}`}
                                             label={columnPanelStore.jsonKeyLabelMap.get(names)} columnPanelStore={columnPanelStore}
                                             names={names} checked/>
                    )
                }

            </DropdownButton>
        );
    }
}

ColumnPanelCheckBoxGroup.propTypes = {
    columnPanelStore: PropTypes.instanceOf(ColumnPanelStore).isRequired,
};

ColumnPanelCheckBoxGroup.defaultProps = {};

@observer
class ColumnPanelCheckBox extends Component {

    get isChecked() {
        let {columnPanelStore, names} = this.props;
        return columnPanelStore.columnMap.get(names);
    }

    toggle() {
        let {columnPanelStore, names} = this.props;
        let checked = this.isChecked;
        columnPanelStore.columnMap.set(names, !checked);
        columnPanelStore.saveToLocalStorage();
    }


    render() {
        const {label, names} = this.props;

        return (
            <Dropdown.Item onClick={this.toggle.bind(this)} eventKey={names}>
                <span style={{width: 12, display: 'inline-block'}}>
                {
                    this.isChecked &&
                    <FaCheck className="text-success"/>
                }
                </span>
                <span className="ms-2">
                    {label}
                </span>
            </Dropdown.Item>
        );
    }
}

ColumnPanelCheckBox.propTypes = {
    label           : PropTypes.string.isRequired,
    columnPanelStore: PropTypes.instanceOf(ColumnPanelStore).isRequired,
    names           : PropTypes.string.isRequired,
    onChange        : PropTypes.func
};


export {ColumnPanelStore, ColumnPanelCheckBoxGroup, ColumnPanelCheckBox};
