import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Styles from "./SearchRangeCalendar.module.scss";
import { DateRangePicker } from "react-date-range"; // 얘가 캘린더 라이브러리
import moment from "moment"; // 날짜 포맷 라이브러리
import ko from "date-fns/locale/ko/index.js";
import { addDays, subDays } from "date-fns";

const SearchRangeCalendar = (prop, ref) => {
  const [item, setItem] = useState(prop.item);
  const [showCal, setShow] = useState(false);
  const clickRef = useRef(null);
  const boxRef = useRef(null);

  const [rangesState, setRanges] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [range, setRange] = useState({
    start_dt: moment(subDays(new Date(), 7)).format("YYYY-MM-DD"),
    end_dt: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && boxRef.current.contains(event.target)) {
        return;
      }

      if (clickRef.current && !clickRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const onClickCalBtn = () => {
    setShow(!showCal);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return range;
    },
  }));

  return (
    <div className={`d-flex ${Styles.search_calendar}`}>
      <div
        ref={boxRef}
        onClick={() => onClickCalBtn()}
        className={`${Styles.range_picker_input_box}`}
      >
        <button></button>
        <span>{range.start_dt}</span>
        <span className={Styles.plain}>To</span>
        <span>{range.end_dt}</span>
      </div>

      {showCal === true ? (
        <div ref={clickRef} className={Styles.range_picker}>
          <DateRangePicker
            staticRanges={[]}
            inputRanges={[]}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={rangesState}
            onChange={(ranges) => {
              const { selection } = ranges;
              setRange({
                start_dt: moment(selection.startDate).format("YYYY-MM-DD"),
                end_dt: moment(selection.endDate).format("YYYY-MM-DD"),
              });

              item.start_dt = moment(selection.startDate).format("YYYY-MM-DD");
              item.end_dt = moment(selection.endDate).format("YYYY-MM-DD");

              setRanges([selection]);
            }}
            locale={ko}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default forwardRef(SearchRangeCalendar);
