import React from 'react';
import Styles from './IncomingMessage.module.scss';
import {Button, Image} from "react-bootstrap";
import { ThreeDots } from 'react-loader-spinner'
import Avatar from "../avatar/Avatar.js";

class TypingUser extends React.Component {

    render() {
        let {user, store} = this.props;

        return (
            <div className={`${Styles.Container}`}>
                <div className={Styles.Avatar}>
                    <Avatar type={user?.type} src={user?.avatar?.fullUrl} seed={user?.id}/>
                </div>
                <div className={Styles.Content}>
                    <div className={Styles.Sender}>
                        {user?.name}
                    </div>
                    <div className={`d-flex`}>
                        <div className={Styles.Message}>
                            <div style={{padding: '.5rem 1rem'}}>
                                <ThreeDots
                                    height="30"
                                    width="30"
                                    radius="9"
                                    color="#acacac"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        </div>

                    </div>


                </div>


            </div>
        )
    }
}

export default TypingUser;

