import React from 'react';
import Styles from './Expired.module.scss';
import Character from './character.svg';
import Cookies from "js-cookie";
import {COOKIE_KEY_BRAND_NM} from "../../const/AppConst";
import {Image} from "react-bootstrap";


class Expired extends React.Component {

    componentDidMount() {
    }

    constructor(props) {
        super(props);

        this.state = {
            brandName : Cookies.get(COOKIE_KEY_BRAND_NM) || '이마트'
        }
    }


    render(){
    return (
        <div className={`w-100 h-100 d-flex flex-column flex-default overflow-hidden`}>
            <div className={Styles.Container}>
                <div className={Styles.Header}>
                    <div className={Styles.NotUseButton}></div>

                    <p  className={Styles.Label}>
                        상담봇
                    </p>

                    <div className={Styles.NotUseButton}></div>
                </div>

                <div className={Styles.Content}>

                    <div className={Styles.Character}>
                        <Image src={Character}/>
                    </div>
                    <div className={Styles.LabelWrap}>
                        <div className={Styles.Title}>
                            <p>만료된 상담입니다.</p>
                        </div>
                        <ul className={Styles.Label}>
                            <li><p>오랫동안 상담을 시작하지 않는 경우 채팅상담 연결이 제한됩니다.</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}


Expired.propTypes = {};

export default Expired;
