import React from 'react';
import {MdImage} from "react-icons/md";
import {AiOutlineExclamationCircle} from "react-icons/ai";

class MentionErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <div>오류</div>;
        }
        return this.props.children;
    }
}

export default MentionErrorBoundary;
