import React, {Children} from 'react';
import {observer} from "mobx-react";
import {Nav, Tab} from 'react-bootstrap';


@observer
class ScrollTabPanel extends React.Component{

    constructor(props) {
        super(props);
    }


    render() {
        let {children, eventKey, activeKey} = this.props;

        return(
            <Tab.Pane eventKey={eventKey} active={eventKey == activeKey} className={`h-100`}>
                {children}
            </Tab.Pane>
        )
    }
}

ScrollTabPanel.propType = {
}

export {ScrollTabPanel};
