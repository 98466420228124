import React from 'react';
import Styles from './ReplyMessage.module.scss';
import {observer} from "mobx-react";
import ContractionMessage from "./ContractionMessage.js";

@observer
class ReplyMessage extends React.Component {

    render() {
        let {message, store} = this.props;
        return (
            <>
                <div className={Styles.Reply} onClick={()=>{store.scrollToReplyMessage(message)}}>
                    <span className={Styles.Name}>{message?.sender?.name} 님에게 답장</span>
                    <ContractionMessage message={message}/>
                </div>
                <hr className={Styles.Line}/>
            </>
        )
    }
}

export {ReplyMessage};
