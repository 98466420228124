import React, { useState } from 'react';
import {Form, Dropdown, Button, InputGroup, Image, Spinner} from 'react-bootstrap';
import {observer} from "mobx-react";
import Styles from './TeamDropDown.module.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {SearchNormal1} from "iconsax-react";
import TeamProfile from "./TeamProfile.js";

const CustomToggle = React.forwardRef(({ children, onClick, team, isFetching}, ref) => (
    <div ref={ref}
         onClick={(e) => {
             e.stopPropagation();
             onClick(e);
         }}
         style={{borderRadius:8, overflow:"hidden"}}
    >
        {
            isFetching?
                <div className={Styles.TeamProfile}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                :
                <TeamProfile team={team}/>
        }

    </div>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, onClick, onClose, show }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div ref={ref}
                 style={style}
                 className={`${show?'d-block':'d-none'} ${Styles.Menu}`}
                 onClick={e=>{
                     e.stopPropagation();
                 }}
            >
                <div className={Styles.Search}>
                    <InputGroup>
                        <InputGroup.Text className={Styles.Icon}>
                            <SearchNormal1 size={24}/>
                        </InputGroup.Text>
                        <Form.Control
                            autoFocus
                            placeholder="팀 검색"
                            onClick={(e)=>{
                                e.stopPropagation();
                            }}
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                    </InputGroup>
                </div>
                <PerfectScrollbar style={{maxHeight:300}}>
                    {
                        React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.team.name.toLowerCase().includes(value.toLowerCase()),
                        ).length > 0 ?
                            React.Children.toArray(children).filter(
                                (child) =>
                                    !value || child.props.team.name.toLowerCase().includes(value.toLowerCase()),
                            )
                            :
                            <div className={Styles.TeamProfile}
                                 onClick={onClose}
                            >
                                <div className={Styles.Avatar}>
                                </div>
                                <div className={Styles.Name}>
                                    검색결과 없음
                                </div>
                            </div>
                    }
                </PerfectScrollbar>
            </div>
        );
    },
);

const CustomItem = React.forwardRef(
    ({ children, style, className, team, onTeamSelect}, ref) => {

        return (
            <div ref={ref}
                 className={className}
                 onClick={(e)=>{
                     onTeamSelect && onTeamSelect(team)
                 }}
            >
                <TeamProfile team={team}/>
            </div>
        );
    },
);

@observer
class TeamDropdown extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            selected: undefined,
            show:false
        }
        let {rsocket} = props;
        this.rsocket = rsocket;
    }

    componentDidMount() {

    }

    load(){
        const {ticket} = this.props;
        let store = this;
        store.setState({isFetching:true});
        store.rsocket.requestChatApi(`/team/transfer/list`,{ticketId: ticket.id})
            .then(r=>{
                store.onOpen()
                this.setState({list:r.data, isFetching:false});
            })
    }

    selectTeam(team) {
        this.setState({selected:team});
        this.onClose();
    }

    onOpen(){
        this.setState({show:!this.state.show})
    }

    onClose(){
        this.setState({show:false})
    }

    render(){
        let {onClick, rsocket, team} = this.props;
        return (
          <div>
            <Dropdown show={this.state.show}>
              <Dropdown.Toggle
                as={CustomToggle}
                team={team || this.state.selected}
                isFetching={this.state.isFetching}
                onClick={(e) => {
                  e.preventDefault();
                  if (!this.state.show) {
                    this.load();
                  } else {
                    this.onClose();
                  }
                }}
              />
              <Dropdown.Menu as={CustomMenu} show={this.state.show} onClose={this.onClose}>
                {this.state.list?.map((team) => (
                  <Dropdown.Item
                    as={CustomItem}
                    team={team}
                    key={team.id}
                    onTeamSelect={(team) => {
                      this.selectTeam(team);
                    }}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
    }

}

export {TeamDropdown as default}
