import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Accordion, Button, Col, Container, Form, Modal, Row, Spinner} from 'react-bootstrap';
import Styles from './ResendDialog.module.scss'

@observer
class ResendDialog extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }


    render() {
        let {store} = this.props;
        return (
            <Modal show={store.isOpenResendDialog}
                   onHide={()=>store.closeSendDialog()}
                   size={'sm'}
                   centered={true}
            >
                <Modal.Body>
                    <div className={Styles.Title}>
                        재전송 하시겠습니까?
                    </div>
                    <div className={Styles.Button}>
                        <Button variant={"outline-danger"}
                                onClick={()=>{
                                    store.deleteMessage(store.targetMessage)
                                }}
                        >
                            삭제
                        </Button>
                        <Button variant={"outline-primary"}
                                onClick={()=>{
                                    store.resendMessage(store.targetMessage)
                                }}
                        >
                            <span>재전송</span>
                        </Button>
                    </div>

                </Modal.Body>

            </Modal>
        );
    }
}

export {ResendDialog as default};
