import React from 'react';
import {FaSort, FaSortDown, FaSortUp} from 'react-icons/fa';
import Sort from '../../store/Sort.js';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import Styles from './DataGrid.module.scss';
import DataGridStore from "../../store/DataGridStore.js";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {InfoCircle} from "iconsax-react";
import { ResizableBox } from 'react-resizable';

/**
 * 데이터그리드 컬럼헤더
 */
@observer
class Header extends React.Component {

    /**
     * 스토어
     */
    get store() {
        return this.props.store;
    }

    get currentSort() {
        return this.store.sort;
    }

    set currentSort(sort) {
        this.store.sort = sort;
    }

    get isSortable() {
        return this.props.sortable || false;
    }

    /**
     * 정렬 아이콘
     * @return {*}
     */
    get sortIcon() {
        if (this.isSortable) {
            if (JSON.stringify(this.currentSort.id) === JSON.stringify(this.props.id)) {
                return this.currentSort.direction === Sort.SORT_DIRECTION.ASC ?
                    <FaSortUp/> : <FaSortDown/>
            } else {
                return <FaSort style={{fill: 'lightgray'}}/>;
            }
        } else {
            return null;
        }
    }

    get info() {
        if(this.props.info){
            return <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props)=>
                    <Popover {...props}>
                        <Popover.Body>
                            {this.props.info}
                        </Popover.Body>
                    </Popover>
                }
            >
                <div className={`d-flex align-items-center`}>
                    <InfoCircle size={16} color={`var(--bs-gray-500)`} style={{marginLeft: "3px", marginRight: "3px"}}/>
                </div>
            </OverlayTrigger>
        } else {
            return null;
        }
    }

    onClick() {
        if (this.isSortable) {
            if (JSON.stringify(this.currentSort.id) === JSON.stringify(this.props.id)) {
                if (this.currentSort.direction === Sort.SORT_DIRECTION.ASC) {
                    this.currentSort.direction = Sort.SORT_DIRECTION.DESC;
                } else {
                    this.currentSort.direction = Sort.SORT_DIRECTION.ASC;
                }
            } else {
                this.currentSort.id = this.props.id;
                this.currentSort.direction = Sort.SORT_DIRECTION.ASC;
            }
        }

        if(!this.store.noPaging) {
            this.store.fetch();
        }

    }


    render() {
        let {store, id} = this.props;
        let width = store?.colMap?.get(id) || store.defaultWidth;
        let {min = 60, max = 9999, current = 100} = width;
        return (
            <th nowrap="nowrap" key={this.props.id} className={`${Styles.Header}`}  >
                <ResizableBox width={current} resizeHandles={['e']} axis={'x'} minConstraints={[min,50]}
                              maxConstraints={[max, 50]}
                              onClick={e=>{e.stopPropagation()}}
                              onResize={(e, {element, size, handle}) => {
                                  store?.onResize &&
                                  store?.onResize(id, size);
                              }}
                >
                    <div className={`d-flex align-items-center me-3 ${this.props.className}`} onClick={() => this.isSortable  && this.onClick()} style={{...this.props.style, wordBreak:'break-word', whiteSpace:'pre'}}>
                        {this.props.name}
                        {this.info}
                        {this.isSortable && this.sortIcon}
                    </div>
                </ResizableBox>
            </th>
        )
    }
}
Header.propTypes = {
    /**
     * 스토어
     */
    store: PropTypes.instanceOf(DataGridStore),

    /**
     * 컬럼 이름
     */
    name: PropTypes.string.isRequired,

    /**
     * 컬럼 id. 문자열
     */
    id: PropTypes.string,

    /**
     * 각 셀용 출력함수
     */
    printFunction: PropTypes.func,

    /**
     * 정렬가능 컬럼여부
     */
    sortable: PropTypes.bool,

    /**
     * 기본표시 하지 않음 여부
     */
    initHide: PropTypes.bool,

    options: PropTypes.arrayOf(PropTypes.object),

    /**
     * 컬럼 설명
     */
    info: PropTypes.string
};

Header.defaultProps = {
    isModifiable: true
};


export default Header;
