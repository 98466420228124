import React from 'react';
import Styles from './ScrollToBottomButton.module.scss'
import {observer} from 'mobx-react';
import {Spinner, Image} from "react-bootstrap";
import {CONTENT_TYPE, MESSAGE_TYPE} from "../../const/ChatConst.js";
import {ArrowDown2} from "iconsax-react";
import {MdAudioFile, MdFilePresent, MdImage, MdVideoFile} from "react-icons/md";
import CustomButton from "../button/CustomButton.js";
import Avatar from "./avatar/Avatar.js";
import ContractionMessage from "./message/ContractionMessage.js";


@observer
class ScrollToBottomButton extends React.Component {

    componentDidMount() {
        let {store} = this.props;
    }

    render() {
        let {store} = this.props;
        let {isBottom, isNewMessage, lastUserMessage} = store;
        return (
            <div className={Styles.ScrollToBottom}>
                <CustomButton className={`${Styles.Button} ${!isBottom ? Styles.isNotBottom : ''} ${isNewMessage && lastUserMessage ? Styles.isNew : ''}`} onClick={()=>store.debounceScrollToBottom()}>
                    {
                        isNewMessage && lastUserMessage &&
                            <div className={Styles.LastMessage}>
                                {
                                    lastUserMessage?.sender &&
                                    <div className={Styles.Sender}>
                                        <div className={Styles.Avatar}>
                                            <Avatar type={lastUserMessage?.sender?.type} src={lastUserMessage?.sender?.avatar?.fullUrl} seed={lastUserMessage?.sender?.id}/>
                                        </div>
                                        {lastUserMessage?.sender?.name}
                                    </div>
                                }
                                <div className={Styles.Content}>
                                    <ContractionMessage message={lastUserMessage}/>
                                </div>
                            </div>
                    }
                    <div className={Styles.Icon}>
                        <ArrowDown2 size={24}/>
                    </div>
                </CustomButton>
            </div>
        )
    }
}

export {ScrollToBottomButton};
