import React, {Component} from 'react';

import {observer} from 'mobx-react';
import {Chat, confirmDialogStore, TICKET_STATUS} from "@qbot-chat/qbot-uikit";
import {channelStore, chatStore, quickMsgStore, rSocket, ticketStore} from '../store/FrontChatStore';
import {navigate, navigateReplace} from '../helper/HistoryHelper';
import {Button, Spinner} from "react-bootstrap";
import {action} from 'mobx';
import Styles from './ChatView.module.scss';
import {CloseCircle} from "iconsax-react";

@observer
class ChatView extends Component {
    constructor(props) {
        super(props);
    }

    @action.bound
    async init() {
        let {match: { params : {channelId }}} = this.props;

        if(rSocket.isReady) {
            try {
                await rSocket.chatStore.setChannel({id: channelId, status: TICKET_STATUS.ACTIVE});
            }catch (e){
                navigate("/close")
            }
        }

    }

    componentDidMount() {
        this.init().then();
    }


    render() {
        return (
            <div className={`d-flex flex-default overflow-hidden`}>
                {
                    rSocket.isReady ?
                        <Chat store={chatStore}
                              backButton={
                                  <div className={Styles.CloseButton}
                                          onClick={()=>{
                                              confirmDialogStore.open({
                                                  title:'종료',
                                                  summary:'상담을 종료하시겠습니까?',
                                                  onOk: ()=>{
                                                      ticketStore.close(chatStore.channelId)
                                                          .then(r=>{
                                                              navigate('/rating')
                                                          })
                                                  }
                                              })

                                          }}
                                  >
                                      <CloseCircle size={32}/>
                                  </div>
                              }
                              onCloseCallback={(channel)=>{
                                  navigate(`/rating`)
                              }}
                        />
                        :
                        <div className={`d-flex justify-content-center align-items-center h-100 w-100`}>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                }
            </div>
        );
    }
}

export default ChatView;