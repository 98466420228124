import React from 'react';
import {Image} from "react-bootstrap";
import Styles from './Content.module.scss'
import {ReplyMessage} from "./ReplyMessage.js";

class ImageContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isError : false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {value, store} = this.props;
        if(value != prevProps.value){
            this.setState({isError:false});
        }
    }


    render() {
        let {value, store, isFirst = true, isLast = true, isBubble, isIncoming, replyMessage} = this.props;
        let {url, fullUrl, size, name } = value;
        let imageUrl = fullUrl;

        return (
            <div className={`${isBubble? Styles.Bubble : ``} ${isIncoming ? Styles.In : Styles.Out} ${isFirst ? Styles.First : ''} ${isLast ? Styles.Last : ''}`}>
                {
                    replyMessage &&
                    <ReplyMessage store={store} message={replyMessage}/>
                }
                {
                    this.state.isError ?
                        <></>
                        :
                        <div className={Styles.ImageContent}>
                            {
                                url && imageUrl &&
                                <Image src={imageUrl}
                                       onLoad={()=>{store?.onImageLoadHandler(store.isBottom)}}
                                       onError={(e)=>{this.setState({isError:true})}}
                                       style={{objectFit:'contain', width:'100%'}}
                                />
                            }
                        </div>

                }
            </div>
        )
    }
}

export {ImageContent};
