import React, {useState} from 'react';
import {Form, FormControl, Modal, Button} from "react-bootstrap";
import {observer} from "mobx-react";
import TeamDropdown from "../../team/TeamDropdown.js";
import Styles from './TransferModal.module.scss'
import {confirmDialogStore} from "../../modal/ConfirmDialog.js";
import {NOTIFICATION_TYPE} from "../../../const/ChatConst.js";

@observer
class TeamTransferModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            reason:''
        }
    }

    onClose(){
        let {store} = this.props;
        this.ref.onClose()
        this.setState({reason:''})
        store.closeTeamTransfer()
    }
    render() {
        let {store} = this.props;
        return(
            <Modal
                show={store.isOpenTeamTransfer}
                onHide={()=>{
                    this.onClose()
                }}
                backdrop="static"
                keyboard={false}
                size={'sm'}
                onClick={()=>{
                    this.ref.onClose();
                }}
            >
                <Modal.Body>
                    <div className={`d-flex flex-column`}>
                        <div className={Styles.Title}>
                            티켓 이관 - 팀
                        </div>
                        <div className={Styles.Agent}>
                            <TeamDropdown rsocket={store.rsocketStore}
                                          ticket={store.channel}
                                          ref={ref=>this.ref = ref}
                            />
                        </div>
                        <div className={Styles.Reason}>
                            <FormControl placeholder={`내용을 입력해 주세요.`}
                                         onClick={(e)=>{
                                             e.stopPropagation();
                                         }}
                                         onChange={e=>{
                                             this.setState({reason:e.target.value})
                                         }}
                            />
                        </div>
                    </div>
                    <div className={Styles.Footer}>
                        <Button variant={"outline-secondary"}
                                onClick={()=>this.onClose()}
                        >
                            취소
                        </Button>
                        <Button variant={'outline-primary'}
                                onClick={()=>{
                                    if(!this.ref.state.selected) {
                                        store.onNotificationHandler(NOTIFICATION_TYPE.WARNING, '이관 팀을 선택해주세요.')
                                        return;
                                    }
                                    if(!this.state.reason) {
                                        store.onNotificationHandler(NOTIFICATION_TYPE.WARNING, '사유를 입력해주세요.')
                                        return;
                                    }
                                    confirmDialogStore.open({
                                        title: '이관',
                                        summary: '이관 하시겠습니까?',
                                        onOk : () => {
                                            store.transfer({
                                                team:this.ref.state.selected,
                                                reason:this.state.reason
                                            }).then(
                                                r=>{
                                                    this.onClose();
                                                }
                                            )
                                        }
                                    })
                                }}
                        >
                            이관
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export {TeamTransferModal as default};
