import * as React from "react";
const DefaultAvatar = (props) => (
    <svg
        id="DefaultAvatar"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 90 90"
        style={{
            enableBackground: "new 0 0 90 90",
        }}
        xmlSpace="preserve"
        {...props}
    >

        <g>
            <path
                className="bg-color"
                fill={props.bgColor}
                d="M0,0v90h20.46c-1.25-2.68-1.93-6.21-1.93-10.84c0-10.72,11.96-19.42,27.46-19.42 c15.5,0,27.46,8.69,27.46,19.42c0,4.63-0.68,8.15-1.93,10.84H90V0H0z M45,57.5c-8.43,0-15.27-6.84-15.27-15.27 S36.57,26.96,45,26.96s15.27,6.84,15.27,15.27S53.43,57.5,45,57.5z M65.16,43.85c0,0.93-0.76,1.69-1.69,1.69s-1.69-0.76-1.69-1.69 v-5.68c0-0.31,0.09-0.6,0.23-0.85c-2.12-7.39-8.94-12.82-17-12.82c-8.07,0-14.88,5.43-17,12.82c0.15,0.25,0.23,0.54,0.23,0.85 l0,5.68c0,0.54-0.26,1.03-0.66,1.34c0.49,2.87,1.67,5.55,3.46,7.86c0.26-0.09,0.54-0.14,0.83-0.14c1.45,0,2.62,1.17,2.62,2.62 s-1.17,2.62-2.62,2.62s-2.62-1.17-2.62-2.62c0-0.61,0.21-1.17,0.56-1.61c-1.92-2.48-3.18-5.36-3.71-8.43 c-0.72-0.19-1.26-0.85-1.26-1.63v-5.68c0-0.93,0.76-1.69,1.69-1.69c0.05,0,0.1,0,0.14,0.01C29.11,28.68,36.4,23,45,23 s15.89,5.68,18.33,13.48c0.05,0,0.09-0.01,0.14-0.01c0.93,0,1.69,0.76,1.69,1.69V43.85z"
            />
            <g>
                <circle className="avatar-color"
                        fill={props.avatarColor}
                        cx={45} cy={42.23} r={15.27} />
                <path
                    className="avatar-color"
                    fill={props.avatarColor}
                    d="M63.47,36.48c-0.05,0-0.1,0-0.14,0.01C60.89,28.68,53.6,23,45,23s-15.89,5.68-18.32,13.48 c-0.05,0-0.09-0.01-0.14-0.01c-0.93,0-1.69,0.76-1.69,1.69v5.68c0,0.78,0.54,1.44,1.26,1.63c0.53,3.07,1.79,5.95,3.71,8.43 c-0.35,0.44-0.56,1-0.56,1.61c0,1.45,1.17,2.62,2.62,2.62c1.45,0,2.62-1.17,2.62-2.62c0-1.45-1.17-2.62-2.62-2.62 c-0.29,0-0.57,0.05-0.83,0.14c-1.8-2.3-2.98-4.99-3.46-7.86c0.4-0.31,0.66-0.8,0.66-1.34l0-5.68c0-0.31-0.09-0.6-0.23-0.85 c2.12-7.39,8.94-12.82,17-12.82c8.07,0,14.88,5.43,17,12.82c-0.15,0.25-0.23,0.54-0.23,0.85v5.68c0,0.93,0.76,1.69,1.69,1.69 s1.69-0.76,1.69-1.69v-5.68C65.16,37.24,64.4,36.48,63.47,36.48z"
                />
                <g>
                    <path
                        className="avatar-color"
                        fill={props.avatarColor}
                        d="M73.46,79.16c0-10.72-11.96-19.42-27.46-19.42c-15.5,0-27.46,8.69-27.46,19.42c0,4.63,0.68,8.15,1.93,10.84 h51.07C72.78,87.32,73.46,83.79,73.46,79.16z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default DefaultAvatar;
