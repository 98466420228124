import React, {Component} from 'react';

import {observer} from 'mobx-react';
import {Close} from "@qbot-chat/qbot-uikit/chat";
import {ticketStore} from "../store/FrontChatStore";
import {navigate} from "../helper/HistoryHelper";
import Styles from "./CloseView.module.scss";

@observer
class CloseView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className={`d-flex flex-column flex-default overflow-hidden`}>
                <div className={Styles.Header}>
                    <div className={Styles.NotUseButton}></div>

                    <p  className={Styles.Label}>
                        상담봇
                    </p>

                    <div className={Styles.NotUseButton}></div>
                </div>

                <Close onRestart={async() => {
                    let newChannel = await ticketStore.createChannel({
                        team: {
                            id:1
                        },
                        scenario: {
                            id:1
                        }
                    });
                    navigate(`/chat/${newChannel.id}`)
                }}/>
            </div>
        );
    }
}

export default CloseView;