import React from 'react';
import Styles from './Content.module.scss'
import {ReplyMessage} from "./ReplyMessage.js";

class AudioContent extends React.Component {
    render() {
        let {value, store, isBubble, isIncoming, replyMessage} = this.props;
        let {url, size, name, fullUrl } = value;
        let audioUrl = fullUrl;
        return (
            <div className={`${isBubble? Styles.Bubble : ``} ${isIncoming ? Styles.In : Styles.Out}`}>
                {
                    replyMessage &&
                    <ReplyMessage store={store} message={replyMessage}/>
                }
                <div className={Styles.AudioContent}>
                    {
                        audioUrl &&
                        <figure style={{objectFit:'contain', width:'100%'}}>
                            <audio src={audioUrl}  autoPlay={false} controls={true}/>
                        </figure>
                    }
                </div>
            </div>
        )
    }
}

export {AudioContent};
