import React from 'react';
import PageLink from './PageLink.js';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {Pagination as Page} from 'react-bootstrap';
import PaginationStore from "../../store/PaginationStore.js";
import {MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext} from "react-icons/md";
import DataGridStore from '../../store/DataGridStore.js';


/**
 * 페이징 블럭
 */
@observer
class Pagination extends React.Component {

    /**
     * 저장소
     * @return {DataGridStore}
     */
    get store() {
        return this.props.store;
    }

    /**
     * 이 블럭에 나열될 페이지들
     * @return {Array}
     */
    get pages() {
        return this.store.pages;
    }

    /**
     * 나열될 페이지의 첫번째 페이지
     * @return {number}
     */
    get paginationBegin() {
        return this.store.paginationBegin;
    }

    /**
     * 나열될 페이지의 마지막 페이지
     * @return {number}
     */
    get paginationEnd() {
        return this.store.paginationEnd;
    }

    /**
     * 총 게시글 수로 파악되는 마지막 페이지
     * @return {number}
     */
    get endPage() {
        return this.store.endPage;
    }

    render() {
        return (
               <Page className={`${this.props.className ? this.props.className : ''} `} size={'sm'} style={{marginBottom: 0, alignItems: 'center'}}>
                    {
                        this.paginationBegin !== 1 &&
                        <PageLink
                            key="first" store={this.store} label={<MdFirstPage className={'d-flex'}/>}
                                  page={1}/>
                    }
                    {
                        this.paginationBegin !== 1 &&
                        <PageLink key={`prev_${this.paginationBegin - 1}`} store={this.store} label={<MdNavigateBefore className={'d-flex'}/>}
                                  page={this.paginationBegin - 1}/>
                    }
                    {
                        this.pages.map(page =>
                            <PageLink key={page} store={this.store} label={page} page={page}/>
                        )
                    }
                    {
                        this.paginationEnd !== this.endPage &&
                        <PageLink key={`next_${this.paginationEnd + 1}`} store={this.store}
                                  label={<MdNavigateNext className={'d-flex'}/>}
                                  page={this.paginationEnd + 1} />
                    }
                    {
                        this.paginationEnd !== this.endPage &&
                        <PageLink key="last" store={this.store} label={<MdLastPage className={'d-flex'}/>}
                                  page={this.endPage}/>
                    }
                </Page>
        )
    }
}

Pagination.propTypes = {
    /**
     * 데이터 스토어
     */
    store    : PropTypes.instanceOf(DataGridStore),
    className: PropTypes.string
};

export default Pagination;
