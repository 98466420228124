import React from 'react';
import Styles from "./ChannelList.module.scss"
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {Channel} from "./Channel.js";
import {NoChannel} from "./NoChannel.js";
import {TicketStore} from "../../store/TicketStore.js";

@observer
class TicketList extends React.Component{

    componentDidMount() {
        let {store} = this.props;
    }

    render() {
        let {store, onChannelClick, header, footer, isAgent = false, renderLabel, onCloseCallback, activeChannel } = this.props;
        return(
            <div className={Styles.Channel}>
                {header && header}
                <div className={Styles.Scroll}>
                    {
                        store?.tickets?.length > 0 ?
                            store?.tickets?.map(channel=>
                                <Channel key={channel.id} channel={channel} store={store} onChannelClick={onChannelClick} isAgent={isAgent} renderLabel={renderLabel} onCloseCallback={onCloseCallback} activeChannel={activeChannel}/>
                            )
                            :
                            <NoChannel/>
                    }
                </div>
                {footer}
            </div>
        )
    }
}


TicketList.propType = {
    store: PropTypes.instanceOf(TicketStore),
    onChannelClick: PropTypes.func,
    header: PropTypes.element
}

export {TicketList};
