/* eslint-disable import/no-anonymous-default-export */
export default {
    control: {
        backgroundColor: '#fff',
        fontSize: '1rem',


        // fontWeight: 'normal',


    },

    '&multiLine': {
        control: {
            fontFamily: 'Noto Sans KR',
            minHeight: 150,

        },
        highlighter: {
            padding: 8,
            border: '1px solid transparent',

        },
        input: {
            padding: 8,
            border: '1px solid transparent',
            borderRadius: '0.5rem',
            outline:'none',
        },
    },

    '&singleLine': {
        display: 'inline-block',
        width: 180,

        highlighter: {
            padding: 1,
            border: '1px inset transparent',
        },
        input: {
            padding: 1,
            border: '1px inset',
        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            fontSize: 14,
            color:'#212529',
            boxShadow:'0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
            borderRadius : '0.5rem',

        },
        item: {
            padding: '0.5rem 1rem',
            '&focused': {
                fontWeight:'bold',
                backgroundColor: '#f8f9fa',
            },
        },
    },
}