import React from 'react';
import {WebsocketClientTransport as RSocketWebsocketClient} from 'rsocket-websocket-client/dist/WebsocketClientTransport';
import {RSocketConnector as RSocketClient} from 'rsocket-core/dist/RSocketConnector';
import axios from 'axios';
import CommonHelper from '../helper/CommonHelper.js';
import {action, makeObservable, observable} from 'mobx';
import {v4 as uuid} from 'uuid';

class RSocket {

    isClosed = false;

    @observable
    sender;
    onNext;
    axiosClient;
    sessionToken;
    url;
    @observable
    apiBaseUrl;
    rsocket;
    initCallback;
    reconnectCallback;
    @observable
    ticketStore;
    @observable
    channelStore;
    @observable
    chatStore;

    @observable
    isReady = false;

    constructor(url, apiBaseUrl, onError) {
        makeObservable(this);
        this.url = url;
        this.apiBaseUrl = apiBaseUrl;
        this.initAxiosClient(this.apiBaseUrl, onError);
    }

    close = async () => {
        this.isClosed = true;
        clearTimeout(this.reconnectTimeout)
        await this.rsocket?.close();
    }

    initAxiosClient = (baseUrl, onError) =>{
        this.axiosClient = axios.create({
            method      : 'post',
            baseURL     : baseUrl,
            timeout     : 20000,
            responseType: 'json'
        });
        this.axiosClient.interceptors.response.use(function (response) {
            // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
            // 응답 데이터가 있는 작업 수행
            return response;
        }, function (error) {
            // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
            // 응답 오류가 있는 작업 수행
            onError && onError(error);
            return Promise.reject(error);
        });
    }

    requestChatApi = async (url, body, config) => {
        return await this.axiosClient.post(
            url,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    ...this.sessionToken && {
                        'x-qbot-session': this.sessionToken
                    }
                },
                ...config
            }
        );
    };

    requestChatMultipartApi = async (url, body, config) => {
        return await this.axiosClient.post(
            url,
            body,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...this.sessionToken && {
                        'x-qbot-session': this.sessionToken
                    }
                },
                ...config

            }
        );
    };

    run = async ({sender, ticket, channel, chat}, initCallback) => {
        this.clear();
        await this.invalidateSession(sender)
        this.sender = sender;
        // 생성하면서 사용자의 세션을 확인한다.

        this.rsocket = await this.createClient({
            url: this.url,
        });
        this.isClosed = false;
        this.setStore(ticket, channel, chat);
        this.initCallback = initCallback;

        await ticket.load();
        await channel.load();
        ticket.connect();
        chat.connectChannel();
        this.reconnectCallback = () => {
            ticket.connect();
            chat.connectChannel();
        }
        initCallback(this);
        this.isReady = true;
        return this;
    };

    setStore = (ticket, channel, chat)=> {
        let store = this;
        ticket.setRSocketStore(store);
        channel.setRSocketStore(store);
        chat.setRSocketStore(store);
        store.ticketStore = ticket;
        store.channelStore = channel;
        store.chatStore = chat;

        return true;
    }

    reconnect = async (attempt) => {
        try {
            if (attempt > 9) {
                console.log('Reconnect failed. server not responding...');
                return;
            }

            // 진짜로 종료한거야?
            if(this.isClosed) {
                console.log('Connection closed.');
                return;
            }

            // resume 은 현재 spring boot 에서 지원하지 않는것 같으니 그냥 reconnect 한다.
            // 실제로 resolve 가 Incomplete RESUME handshake 로 실패하는 경우는 없게 된다.
            console.log('reconnect attempt: ' + attempt);

            this.rsocket = await this.createClient({
                url: this.url,
            });

            this.initCallback(this);
            this.reconnectCallback();
        } catch ( err ) {
            setTimeout(() => this.reconnect(attempt + 1), 10000);
        }

    }

    createClient = async (options, responder) => {
        const store = this;
        const transportOptions = {
            url      : `${options.url}`,
            wsCreator: (url) => {
                return new WebSocket(url);
            }
        };

        const setupOptions = {
            keepAlive       : 20000,
            lifetime        : 90000,
            dataMimeType    : 'application/json',
            metadataMimeType: 'message/x.rsocket.routing.v0',
            payload         : {
                data: Buffer.from(this.sessionToken)
            },
        };
        const resume = {
            tokenGenerator   : () => Buffer.from(uuid()),
            reconnectFunction: attempt =>
                new Promise((resolve, reject) => {
                    this.reconnect(1);
                })

        };

        const transport = new RSocketWebsocketClient(transportOptions);
        const client = new RSocketClient({setup: setupOptions, transport, resume});

        let rSocket = await client.connect();
        console.log('Client connected to the RSocket server');
        return rSocket;

    };

    requestStream = (onNext) => {
        console.log('스트림 요청 호출.');
        this.onNext = onNext;

        const store = this;
        return this.rsocket?.requestStream(
            {
                data    : undefined,
                metadata: CommonHelper.generateMetadata(`channel/stream/${this.sessionToken}/${this.sender.id}`)
            },
            2147483647,
            {
                onNext(payload, isComplete) {
                    onNext(payload, isComplete);
                },
                onError(error) {
                    console.log('에러');
                    console.log(error);
                },
                onExtension(extendedType, content, canBeIgnored) {
                    console.log('연장');
                    console.log(extendedType);
                    console.log(content);
                    console.log(canBeIgnored);
                },
                onComplete() {
                    console.log('complete?');
                }
            }
        );
    };

    @action.bound
    async invalidateSession(sender) {
        const {} = await this.requestChatApi(
            `/user/invalidate-session`,
            {}
        );
        const result= await this.requestChatApi(
            `/user/issue-session/`,
            {}
        );
        this.sessionToken = result?.headers['x-qbot-session'];
        await this.requestChatApi(
            `/user/issue-session/${sender.id}`,
            sender
        );

    }

    setQuickMsgStore(quickMsgStore) {
        this.quickMsgStore = quickMsgStore;
    }

    notificationHandler;

    setNotificationHandler(notificationHandler){
        this.notificationHandler = notificationHandler;
    }

    onNotificationHandler(type, message){
        this.notificationHandler && this.notificationHandler(type, message)
    }

    requestTicketStream = (onNext) => {
        console.log('스트림 요청 호출.');
        this.onNext = onNext;

        const store = this;
        return this.rsocket?.requestStream(
            {
                data    : undefined,
                metadata: CommonHelper.generateMetadata(`event/${this.sessionToken}/${this.sender.id}`)
            },
            2147483647,
            {
                onNext(payload, isComplete) {
                    onNext(payload, isComplete);
                },
                onError(error) {
                    console.log('에러');
                    console.log(error);
                },
                onExtension(extendedType, content, canBeIgnored) {
                    console.log('연장');
                    console.log(extendedType);
                    console.log(content);
                    console.log(canBeIgnored);
                },
                onComplete() {
                    console.log('complete?');
                }
            }
        );
    };

    clear(){
        let store = this;
        if(store.channelStore){
            store.channelStore.clear();
        }
        if(store.ticketStore){
            store.ticketStore.clear();
        }
        if(store.chatStore){
            store.chatStore.clear();
        }

    }
}

export {RSocket};
