import React from 'react';
import Styles from "./SettingPanel.module.scss"
import PropTypes from 'prop-types';
import {ChatStore} from "../../store/ChatStore.js";
import {observer} from "mobx-react";
import {Button} from "react-bootstrap";
import {MdOutlineExitToApp, MdOutlineTransferWithinAStation} from "react-icons/md";

@observer
class SettingPanel extends React.Component{

    render() {
        let {store, callbackLeave} = this.props;
        let tabIndex = store.isSettingOpen ? 1 : -1
        return(
            <div className={`${Styles.BackDrop} ${store.isSettingOpen ? Styles.Active : ''}`}
                 onClick={()=>store.isSettingOpen = false}
            >
                <div className={`${Styles.SettingPanel} ${store.isSettingOpen ? Styles.Active : ''}`} >
                    <Button variant={'outline-success'}
                            size={'sm'}
                            tabIndex={tabIndex}
                            onClick={()=>{

                            }}
                    >
                        <MdOutlineTransferWithinAStation/>&nbsp;이관
                    </Button>
                    <Button variant={'outline-danger'}
                            size={'sm'}
                            tabIndex={tabIndex}
                            onClick={()=>{

                            }}
                    >
                        <MdOutlineExitToApp/>&nbsp;종료
                    </Button>
                    <Button variant={"outline-dark"}
                            tabIndex={tabIndex}
                            onClick={()=>{
                                callbackLeave && callbackLeave(
                                    store.channelId,
                                    ()=>{
                                        store.messageMap.replace(store.messages.filter(message=>message.channelId != store.channelId).map(message=>[message.id,message]));
                                        store.channelId = undefined;

                                    });
                            }}
                    >
                        방 나가기
                    </Button>
                </div>
            </div>
        )
    }
}

SettingPanel.propType = {
    store: PropTypes.instanceOf(ChatStore)
}

export {SettingPanel};
