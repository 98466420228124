import {action, computed, makeObservable, observable} from 'mobx';
import React from 'react';
import CommonHelper from "../helper/CommonHelper.js";

/**
 * 페이징 저장소
 */
class PaginationStore {

    constructor() {
        makeObservable(this);
    }

    isNoPaging = false;

    @observable count = 0;

    /**
     * 현재 페이지
     * @type {number}
     */
    @observable page = 1;

    /**
     * 페이징 블럭 사이즈
     * @type {number}
     */
    paginationSize;

    /**
     * 페이지당 행 수
     * @type {number}
     */
    @observable
    rowsPerPage;

    @observable
    isChangeRowsPerPage = true;

    @observable sort;

    @observable isNoPaging = false;

    @computed
    get parameterizeSort() {
        return this.sort;
    }

    @computed
    get searchConditionWithPage() {
        let m = {};
        this.searchCondition.forEach((v, k) => {
            if (/\./.test(k)) {
                this.assignKeysValue(m, this.searchCondition, k.split(/\./), v);
            } else {
                m[k] = v;
            }
        });
        if(!this.isNoPaging){
            m.pagingOption = {
                page       : this.page,
                rowsPerPage: this.rowsPerPage,
                sort       : this.parameterizeSort
            };
        }
        return m;
    }

    /**
     * 현재 검색 조건
     * @type {Map}
     */
    @observable searchCondition = new Map();

    @action.bound
    changePage(page) {
        this.page = page;
    }

    onPageButtonClick(page) {
        this.changePage(page);
    }

    /**
     * 페이지당 행 수 변경에 따른 기본동작
     */
    changeRowsPerPage(rowsPerPage) {
        this.rowsPerPage = rowsPerPage;
    }


    /**
     * 계산된 페이지 목록
     * @returns {Array}
     */
    @computed
    get pages() {
        return CommonHelper.range(this.paginationBegin, this.paginationEnd + 1);
    }

    /**
     * 페이지 - 1
     * @returns {number}
     */
    get zeroBasePageIndex() {
        return this.page - 1;
    }

    /**
     * 끝 페이지
     * @returns {number}
     */
    get endPage() {
        return Math.floor(this.count / this.rowsPerPage) +
            (this.count % this.rowsPerPage === 0 ? 0 : 1);
    }

    /**
     * 페이징 블럭 첫 페이지
     * @returns {number}
     */
    get paginationBegin() {
        return Math.floor(this.zeroBasePageIndex / this.paginationSize) * this.paginationSize + 1;
    }

    /**
     * 페이징 블럭 끝 페이지
     * @returns {number}
     */
    get paginationEnd() {
        const mayBlockPageEnd = this.paginationBegin + this.paginationSize - 1;

        return mayBlockPageEnd > this.endPage ? this.endPage : mayBlockPageEnd;
    }

    assignKeysValue(obj, m, keys, value) {
        let [head, ...tail] = keys;
        let nextObj = obj;
        let key = head;
        let rest = tail;
        while (rest.length > 0) {
            let found = nextObj[key];
            if (!found) {
                let newObj = {};
                nextObj[key] = newObj;
                nextObj = newObj;
            } else {
                nextObj = found;
            }
            let [nextHead, ...nextTail] = rest;
            rest = nextTail;
            key = nextHead;
        }
        nextObj[key] = value;
    }
}

export default PaginationStore;
