import * as React from "react";

const SVGComponent = (props) => {
    const {size = 24, parentColorClassName, color=''} = props;
    return <svg
        id="TicketEmptyIcon"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style={{
            enableBackground: "new 0 0 24 24",
            width: size,
            height: 'auto',
            fill:color,
            className: parentColorClassName,  //이부분의 스타일을 적용함 fill등..
        }}
        xmlSpace="preserve"
        {...props}
    >

        {/*--여기까지는 기본값--*/}

        <path
            
            d="M-27.4,13.4c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C-28.8,12.8-28.2,13.4-27.4,13.4 z"
        />
        <path
            
            d="M-21.6,13.4c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.5-1.3,1.2C-22.9,12.8-22.3,13.4-21.6,13.4 z"
        />
        <path
            
            d="M-13.5,7.9c-0.9-1-2.2-1.6-3.7-1.8l0,0c-0.2,0-0.4,0-0.6,0h-6c0.1-0.3,0.2-0.6,0.5-0.9 c0.5-0.6,1.4-0.8,2.2-0.9c0.2,0.6,0.8,1,1.5,1c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6c-0.5,0-1,0.2-1.3,0.6 c-1.1,0.1-2.5,0.5-3.5,1.4c-0.6,0.5-0.9,1.2-1,2h-2.5c-1.7,0-3.1,0.6-4.2,1.6c-1,1-1.6,2.5-1.6,4.1v2.9c0,0.6,0.1,1.1,0.2,1.7 c0.6,2.5,2.8,4,5.5,4h6.1l1.9,2c0.3,0.2,0.6,0.3,0.9,0.3s0.6,0,0.9-0.2c0.3-0.1,0.5-0.4,0.7-0.6c0.2-0.3,0.2-0.6,0.2-0.9v-0.6 c1.4-0.1,2.5-0.7,3.4-1.5c1-1,1.6-2.5,1.6-4.1v-3C-12.1,10.3-12.6,8.9-13.5,7.9L-13.5,7.9z M-13.7,14.7c0,1.3-0.4,2.4-1.2,3.1 c-0.7,0.7-1.8,1.1-3.1,1.1c-0.4,0-0.8,0.3-0.8,0.8V21v0.1c0,0,0,0.1-0.1,0.1H-19h-0.1l-2.1-2.1c-0.1-0.1-0.3-0.1-0.4-0.1h-6.3 c-2.2,0-3.6-1.1-4.1-2.9l0,0c-0.1-0.4-0.2-0.8-0.2-1.3v-2.9c0-1.3,0.4-2.4,1.2-3.1c0.7-0.8,1.8-1.2,3.1-1.2h10c0.2,0,0.3,0,0.5,0 c1.2,0.1,2.1,0.6,2.7,1.3c0.6,0.7,1,1.7,1,2.9V14.7L-13.7,14.7z"
        />
    
        <g>
            <path
                
                d="M15.4,9.6l4.5-4.5L21,4.1l1.5-1.5c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0l-2,2C18.8,3.3,18,3.2,17,3.2 H7C2.6,3.2,1.2,4.6,1.2,9v0.5c0,0.4,0.3,0.8,0.8,0.8c1,0,1.8,0.8,1.8,1.8S3,13.8,2,13.8c-0.4,0-0.8,0.3-0.8,0.8V15 c0,2.6,0.5,4.1,1.7,4.9l-1.5,1.5c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l2-2 l1.3-1.3l9.2-9.2L15.4,9.6z M14,8.9l-9.9,9.9c-1-0.5-1.3-1.5-1.4-3.7c1.4-0.3,2.5-1.6,2.5-3.2S4.2,9.2,2.8,8.8 c0-3.4,0.7-4.1,4.2-4.1h7.2v1.8c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8V4.8H17c0.4,0,0.8,0,1.2,0l-3.8,3.8L14,8.9z"
            />
            <path
                
                d="M14.9,12.1c-0.4,0-0.8,0.3-0.8,0.8v1c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-1 C15.7,12.4,15.3,12.1,14.9,12.1z"
            />
            <path
                
                d="M22,10.8c0.4,0,0.8-0.3,0.8-0.8V9c0-0.9-0.1-1.6-0.2-2.3l0,0c-0.1-0.4-0.4-0.6-0.7-0.6c-0.4,0-0.8,0.3-0.8,0.8 c0,0.1,0.2,1.4,0.2,2.2v0.3c-1.4,0.3-2.5,1.6-2.5,3.2c0,0.7,0.2,1.4,0.7,2c0.5,0.6,1.1,1,1.8,1.1c-0.1,3-0.9,3.6-4.2,3.6h-1.3v-1.8 c0-0.4-0.3-0.8-0.8-0.8c-0.4,0-0.8,0.3-0.8,0.8v1.8H8.2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8H17c4.4,0,5.8-1.3,5.8-5.8 c0-0.4-0.3-0.8-0.8-0.8c-1,0-1.8-0.8-1.8-1.8S21,10.8,22,10.8z"
            />
        </g>
    </svg>
};
export default SVGComponent;