import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {TICKET_STATUS, Rating} from "@qbot-chat/qbot-uikit/chat";
import {chatStore, rSocket, ticketStore} from "../store/FrontChatStore";
import {goBack, navigate} from "../helper/HistoryHelper";
import Cookies from "js-cookie";
import {COOKIE_KEY_CHANNEL_ID} from "../const/AppConst";
import Styles from "./RatingView.module.scss";
import {ArrowLeft2, CloseCircle} from "iconsax-react";

@observer
class RatingView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className={`d-flex flex-column flex-default overflow-hidden`}>
                <div className={Styles.Header}>
                    <div
                        className={Styles.UseButton}
                        onClick={()=>{goBack()}
                        }>
                        <ArrowLeft2
                            size="32"
                            color="var(--bs-secondary)"
                        />
                    </div>

                    <p  className={Styles.Label}>
                        고객만족도조사
                    </p>

                    <div
                        onClick={()=> {
                            chatStore.setChannel(null);
                            navigate('/close');
                        }}
                        className={Styles.UseButton}>
                        <CloseCircle
                            size="32"
                            color="var(--bs-secondary)"
                        />
                    </div>
                </div>
                <Rating
                    store={chatStore}
                    onClosed={()=>{
                            chatStore.setChannel(null);
                            navigate('/close');
                        }}
                />
            </div>
        );
    }
}

export default RatingView;