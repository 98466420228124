import * as React from "react";
const SVGComponent = (props) => (
    <svg
        id="DefaultCustomerImage"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 90 90"
        style={{
            enableBackground: "new 0 0 90 90",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill={props.bgColor}
            d="M0,0v90h8.7h11.8h0c-1.2-2.7-1.9-6.2-1.9-10.8c0-10.7,12-19.4,27.5-19.4s27.5,8.7,27.5,19.4 c0,4.6-0.7,8.2-1.9,10.8h8.3H90V0H0z M45,57.5c-8.4,0-15.3-6.8-15.3-15.3C29.7,33.8,36.6,27,45,27s15.3,6.8,15.3,15.3 C60.3,50.7,53.4,57.5,45,57.5z"
        />
        <g>
            <circle fill={props.avatarColor} cx={45} cy={42.2} r={15.3} />
            <path
                fill={props.avatarColor}
                d="M46,59.7c-15.5,0-27.5,8.7-27.5,19.4c0,4.6,0.7,8.1,1.9,10.8h51.1c1.2-2.7,1.9-6.2,1.9-10.8 C73.5,68.4,61.5,59.7,46,59.7z"
            />
        </g>
    </svg>
);
export default SVGComponent;
