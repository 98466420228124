import React from 'react';
import Styles from "./Content.module.scss";
import {ReplyMessage} from "./ReplyMessage.js";

class VideoContent extends React.Component {
    render() {
        let {value, store, isBubble, isIncoming, replyMessage} = this.props;
        let {url, size, name, fullUrl } = value;
        let videoUrl = fullUrl;
        return (
            <div className={`${isBubble? Styles.Bubble : ``} ${isIncoming ? Styles.In : Styles.Out}`}>
                {
                    replyMessage &&
                    <ReplyMessage store={store} message={replyMessage}/>
                }
                <div className={Styles.VideoContent}>
                    {
                        videoUrl &&
                        <video src={videoUrl}  style={{objectFit:'contain', width:'100%'}} autoPlay={false} controls={true}/>
                    }
                </div>
            </div>
        )
    }
}

export {VideoContent};
