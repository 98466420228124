import React, {Component} from 'react';
import Styles from './App.module.scss';
import {Redirect, Route, Router, Switch, withRouter} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {observer} from 'mobx-react';
import {HISTORY, navigateReplace} from './helper/HistoryHelper';
import {v4 as uuid} from "uuid";
import {
    COOKIE_KEY_NICKNAME,
    COOKIE_KEY_SCENARIO_ID,
    COOKIE_KEY_USER_ID,
    COOKIE_KEY_USER_KEY
} from './const/AppConst';
import ChatView from './view/ChatView';
import CloseView from './view/CloseView';
import RatingView from "./view/RatingView";
import {makeObservable, observable} from 'mobx';
import Cookies from 'js-cookie';
import qs from 'qs';
import Maintenance from './view/maintenance/Maintenance';
import Expired from './view/close/Expired';
import InitView from "./view/InitView";
import {channelStore, chatStore, quickMsgStore, rSocket, ticketStore} from "./store/FrontChatStore";
import {ADJECTIVES, ANIMALS} from "@qbot-chat/qbot-uikit";
import 'moment/locale/ko';

class DivWith extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return (
            <>
                {this.props.children}
            </>
        );
    }
}

const DivWithRouter = withRouter(DivWith);

const NotFound = () => {
    return (
        <div>
            <h1>페이지가 없습니다.</h1>
        </div>
    );
};

@observer
class App extends Component {

    error = false;
    reload = false;


    constructor(props) {
        super(props);

        let user_id;
        let scenarioId;
        let nickname;
        if (window.location.search) {
            const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true});

            if(queryParams.hasOwnProperty('user_id')){
                user_id = queryParams?.user_id;
                scenarioId = queryParams?.scenarioId;
                nickname = queryParams?.nickname;
                Cookies.set(COOKIE_KEY_USER_KEY, user_id);
                Cookies.set(COOKIE_KEY_SCENARIO_ID, scenarioId);
    
                if (nickname)
                    Cookies.set(COOKIE_KEY_NICKNAME, nickname);
                else
                    Cookies.remove(COOKIE_KEY_NICKNAME);
            }

            this.reload = true;
            // clear query string
            //indow.history.replaceState({}, document.title, "/");
        } else {
            console.log(Cookies.get(COOKIE_KEY_USER_KEY))
            if(!Cookies.get(COOKIE_KEY_USER_KEY))
                Cookies.set(COOKIE_KEY_USER_KEY, uuid());
        }

        if (this.error) {
            console.log('사용자 id가 비어있음');
        }

    }

    componentDidMount() {
        const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        rSocket.requestChatApi(`/user/search`,
            {
                key  : Cookies.get(COOKIE_KEY_USER_KEY),
                name: `${ADJECTIVES[Math.floor(Math.random() * ADJECTIVES.length)]} ${ANIMALS[Math.floor(Math.random() * ANIMALS.length)]}`
            }).then(({data:customer})=>{
            rSocket.run(
                {
                    sender : customer,
                    ticket : ticketStore,
                    channel: channelStore,
                    chat   : chatStore,
                },
                rsocket => {
                    quickMsgStore.setRSocketStore(rSocket);
                    chatStore.setQuickMsgStore(quickMsgStore);
                }
            );

        });
    }

    render() {
        return (
            <div className={`d-flex flex-default ${Styles.Content}`}>
                {
                    rSocket.isReady &&
                    <Router history={HISTORY}>
                        <DivWithRouter>
                            <Switch>
                                <Route path="/init" component={InitView}/>
                                <Route path="/chat/:channelId" component={ChatView}/>
                                <Route path="/close" component={CloseView}/>
                                <Route path="/rating" component={RatingView}/>
                                <Redirect exact from="/" to="/init"/>
                            </Switch>
                        </DivWithRouter>
                    </Router>
                }
            </div>
        );
    }
}

export default App;