import React, {Component} from 'react';

import {observer} from 'mobx-react';
import {channelStore, chatStore, quickMsgStore, rSocket, ticketStore} from '../store/FrontChatStore';
import {Button, FormControl, Spinner} from "react-bootstrap";
import Cookies from "js-cookie";
import {COOKIE_KEY_TICKET_ID} from "../const/AppConst";
import {navigate} from "../helper/HistoryHelper";
import {ADJECTIVES, ANIMALS} from "@qbot-chat/qbot-uikit";
import qs from 'qs';

@observer
class InitView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId:'SbMRn5Y5w/g93xLLoM1ZJQ==',
            scenarioId: 1
        }
    }

    componentDidMount() {
        if (window.location.search) {
            const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true});
            if(queryParams.hasOwnProperty('channel_id')){
                console.log(queryParams);
                console.log(queryParams.channel_id);
                let channel_id = queryParams.channel_id;
                console.log(channel_id);
                navigate(`/chat/${channel_id}`);
            }
        }
    }




    render() {
        return (
            <div className={`d-flex flex-default overflow-hidden`}>
                <div>
                    <FormControl disabled={true} value={this.state.userId}
                                 onChange={e=>this.setState({userId:e.target.value})}
                    />
                    <FormControl value={this.state.scenarioId}
                                 onChange={e=>this.setState({scenarioId:e.target.value})}
                    />
                    <Button onClick={async() => {
                                let newChannel = await ticketStore.createChannel({
                                    team: {
                                        id:1
                                    },
                                    scenario: {
                                        sid:this.state.scenarioId
                                    }
                                });
                                navigate(`/chat/${newChannel.id}`)
                            }}
                    >
                        상담 시작
                    </Button>
                </div>

            </div>
        );
    }
}

export default InitView;