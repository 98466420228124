import {action, computed, makeObservable, observable, values, toJS} from "mobx";
import React from "react";
import {USER_TYPE} from "../const/ChatConst.js";

class QuickMsgStore {

    constructor() {
        makeObservable(this);
    }

    rsocketStore;

    scrollRef = React.createRef();

    elmRefs = [];

    @observable
    isOpen = false;

    @observable
    isInitialLoad = false;

    @observable
    isFetching = false;

    @observable
    hasMore = false;

    @observable
    isBottom = true;

    @observable
    quickMsgListMap = new Map();

    @observable
    prevId = null;

    @observable
    keyword = "";

    @observable
    prevKeyword = "";

    @observable
    cursor = -1;

    @observable
    isPopupOpen = false;

    setRSocketStore(rsocketStore){
        this.rsocketStore = rsocketStore;
    }

    @computed
    get quickMsgList() {
        return toJS(values(this.quickMsgListMap))
    }

    @action.bound
    setIsOpen(isOpen) {
        if(this.rsocketStore == null
            || ![USER_TYPE.AGENT, USER_TYPE.AGENT_LEADER].includes(this.rsocketStore.sender.type)
        ) return;
        this.isOpen = isOpen;
        this.quickMsgListMap = new Map();
        this.prevId = null;
        this.cursor = -1

        if(this.isOpen){
            this.load("");
        }
    }

    @action.bound
    setCursor(cursor) {
        this.cursor = cursor;
    }

    @action.bound
    setIsPopupOpen(isPopupOpen) {
        this.isPopupOpen = isPopupOpen;
        if(this.isPopupOpen) this.isOpen = false;
    }

    @action.bound
    load = async (msg) => {
        if(msg != null) this.keyword = msg.slice(1);

        if(this.keyword != null &&  this.keyword !== "" && this.prevKeyword !== this.keyword) {
            this.quickMsgListMap = new Map();
            this.prevId = null;
            this.cursor = -1;
        }

        if(this.prevKeyword !== this.keyword) {
            this.prevKeyword = this.keyword;
        }

        let {data: {items, rowsCount = false}} = await this.rsocketStore.requestChatApi(`/quickmsg/infinite/list`,
            {
                keyword: this.keyword,
                prevId: this.prevId,
                count: 5
            }
        );
        this.hasMore = rowsCount >= 5;
        let quickMsgList = this.quickMsgList;
        this.quickMsgListMap.replace(quickMsgList.concat(items).map(quickMsg => [quickMsg.id, quickMsg]));
        this.quickMsgList.forEach((msg,idx) => this.elmRefs[idx] = React.createRef());
        if(items.length !== 0){
            this.prevId = items.reverse()[0].id
        }


    }

    @action.bound
    loadMore = async () => {
        let store = this;
        if(store.isFetching)
            return;
        store.isFetching = true;
        await store.load();
        store.isFetching = false;
    }

    onScrollHandler = (e) => {
        let isBottom = (this.scrollRef?.current?.scrollHeight - this.scrollRef?.current?.scrollTop) === (this.scrollRef?.current?.clientHeight);
        if(isBottom){
            this.load();
        }
        this.isBottom = isBottom;
    }
}

export {QuickMsgStore}