import React from 'react';
import {observer} from "mobx-react";
import {Nav, Tab, TabContent} from 'react-bootstrap';
import Styles from './ScrollTab.module.scss'

@observer
class ScrollTabNav extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        let {activeKey, onClick, className} = this.props;
        return(
            <div className={`${Styles.Nav} ${className}`}>
                {
                    React.Children.map(this.props.children,
                        child => {
                            const {eventKey} = child.props;

                            return (React.cloneElement(child, {
                                    key    : name,
                                    onClick,
                                    isActive: activeKey === eventKey,
                                    ...child.props
                                })
                            );
                        })
                }
            </div>
        )

    }
}

ScrollTabNav.propType = {
}

export {ScrollTabNav};
