import React from 'react';
import Styles from "./Channel.module.scss"
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {ChatStore} from "../../store/ChatStore.js";
import moment from "moment";
import {CHAT_EVENT_TYPE, TICKET_STATUS} from "../../const/ChatConst.js";
import {Badge, Button, OverlayTrigger, Popover} from "react-bootstrap";
import ContractionMessage from "../chat/message/ContractionMessage.js";
import ContractionMessageErrorBoundary from "../chat/message/ContractionMessageErrorBoundary.js";
import {Copy} from "iconsax-react";
import {NotificationManager} from "react-notifications";


@observer
class Channel extends React.Component{
    handleCopyClipBoard = async (text) => {
        try {
            await navigator.clipboard.writeText (text);
            NotificationManager.info('클립보드에 복사되었습니다.');
        } catch (e) {
            NotificationManager.info('복사에 실패하였습니다');
        }
    };

    render() {
        let {store, channel, onChannelClick, location, isAgent = false, renderLabel, onCloseCallback, activeChannel} = this.props;
        let {id, createdAt, lastMessage, isConfirm = true, notConfirmCount = 0, isFinished = false, finishedType, assignment, customer} = channel;

        return (
          <div className={`position-relative`}>
            <div
              className={`${Styles.ChannelBox} ${!isConfirm && isAgent ? Styles.On : ''} ${id === activeChannel?.id ? Styles.Active : ''}`}
              onClick={() => {
                if (channel?.status != TICKET_STATUS.CLOSED) {
                  store.enterChannel(channel).then(() => {
                    onChannelClick && onChannelClick(channel);
                  });
                } else {
                  onChannelClick && onChannelClick(channel);
                }
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                store.openDropDown(channel, e.pageX, e.pageY);
              }}
            >
              <div className={Styles.ChannelRoom}>
                <div className={Styles.RoomDec}>{renderLabel && renderLabel(channel)}</div>

                <div className={Styles.RoomCreatedAt}>
                  <div>{moment(lastMessage?.createdAt).fromNow()}</div>
                </div>
              </div>
              <div className={Styles.ChannelId}>
                {assignment?.reason && (
                  <OverlayTrigger
                    placement='bottom'
                    overlay={(props) => (
                      <Popover {...props}>
                        <Popover.Body>{assignment?.reason}</Popover.Body>
                      </Popover>
                    )}
                  >
                    {({ ref, ...triggerHandler }) => (
                      <Badge bg={'warning'} className={`me-2`} ref={ref} {...triggerHandler}>
                        이관
                      </Badge>
                    )}
                  </OverlayTrigger>
                )}
                <div className={Styles.TicketId}>{channel.customer.name}</div>
                <div
                  className={Styles.CopyButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleCopyClipBoard(channel.id);
                  }}
                >
                  <Copy size='16' color='#6C757D' />
                </div>
              </div>

              <div className={Styles.ChannelDec}>
                <div className={Styles.Message}>
                  {lastMessage && (
                    <ContractionMessageErrorBoundary>
                      <ContractionMessage message={lastMessage} />
                    </ContractionMessageErrorBoundary>
                  )}
                </div>

                <div>{notConfirmCount > 0 && <div className={`${Styles.NewMessageNum}`}>{notConfirmCount > 99 ? '99+' : notConfirmCount}</div>}</div>
              </div>
            </div>
            {isFinished && (
              <div className={Styles.BackDrop}>
                <div className={Styles.CloseDialog}>
                  <div className={Styles.Title}>{finishedType == CHAT_EVENT_TYPE.CLOSE ? `종료` : '이관'}된 티켓 입니다.</div>
                  <div className={Styles.Button}>
                    <Button
                      variant={'outline-primary'}
                      size={`sm`}
                      onClick={() => {
                        store.deleteTicket(id);
                        onCloseCallback && onCloseCallback(channel);
                      }}
                    >
                      확인
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
    }

}

Channel.propType = {
    store: PropTypes.instanceOf(ChatStore)
}

export {Channel};
