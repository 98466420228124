import React, {Component} from 'react';
import {observer} from "mobx-react";
import Styles from './QuickMsg.module.scss';
import InfiniteScroll from "react-infinite-scroller";
import {Badge, Spinner, OverlayTrigger, Popover, Button, Tooltip} from "react-bootstrap";
import {AddSquare, MessageAdd} from "iconsax-react";


const TYPE = {
    COMMON: '공통',
    PERSONAL: '개인'
}

const ColoredItem = ({item, query}) => {
    return query && item.includes(query) ? (
        <>
            {
                item.split(query).length > 2 ?
                    item.split(query).map((text, idx) => {
                        return item.split(query).length > 2 && idx < item.split(query).length - 1 && <span key={idx}>
                        {text}
                            <span style={{color: "#0BB7AF"}}>{query}</span>
                        </span>
                    })
                    :
                    (<>
                        {item.split(query)[0]}
                        <span style={{color: "#0BB7AF"}}>{query}</span>
                        {item.split(query)[1]}
                    </>)
            }
        </>
    ) : (
        <>{item}</>
    );
};

@observer
class QuickMsg extends Component {

    constructor(props) {
        super(props);
    }

    keyboardNavigation = (e) => {
        const {store} = this.props;
        if(store.quickMsgStore){
            if(store.quickMsgStore.quickMsgList.length > 0){
                if (e.key === 'ArrowDown') {
                    if (store.quickMsgStore.isOpen) {
                        store.quickMsgStore.setCursor(store.quickMsgStore.cursor < store.quickMsgStore.quickMsgList.length - 1 ? store.quickMsgStore.cursor + 1 : store.quickMsgStore.cursor)
                        store.quickMsgStore.elmRefs[store.quickMsgStore.cursor].current?.scrollIntoView();
                    }

                }
                if (e.key === 'ArrowUp') {
                    if (store.quickMsgStore.isOpen) {
                        store.quickMsgStore.setCursor(store.quickMsgStore.cursor > 0 ? store.quickMsgStore.cursor - 1 : 0)
                        store.quickMsgStore.elmRefs[store.quickMsgStore.cursor].current?.scrollIntoView();
                    }
                }
                if (e.key === 'Escape') {
                    store.quickMsgStore.setCursor(-1);
                    store.quickMsgStore.setIsOpen(false);
                }
                if (e.key === 'Enter' && store.quickMsgStore.cursor > -1) {
                    store.quickMsgFn(store.quickMsgStore.quickMsgList[store.quickMsgStore.cursor].content);
                    store.quickMsgStore.setCursor(-1);
                    store.quickMsgStore.setIsOpen(false);
                }
            }
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyboardNavigation);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyboardNavigation);
    }


    mouseEnter = (index) => {
        const {store} = this.props;
        store.quickMsgStore.setCursor(index);
    };


    render() {
        const {store} = this.props;

        return (
            <>
                { store.quickMsgStore &&
                    <div className={Styles.ContentContainer} style={{height: store.quickMsgStore.isOpen  ? '20rem' : 0}}>
                        <div className={Styles.Header}>
                            <div>
                                #빠른답변
                            </div>
                            <div className={'d-flex'}>
                                <Button className={'d-flex align-items-center text-decoration-none'} variant="link" style={{color: '#ffffff', alignSelf: 'center'}}
                                        onClick={() => {
                                            store.quickMsgFn("");
                                            store.quickMsgStore.setIsPopupOpen(true);
                                        }}
                                >
                                    <AddSquare
                                        size="20"
                                        color="#FFFFFF"
                                        variant="Outline"
                                    />
                                    <span className={'ms-2 text-decoration-none'}>
                                    빠른답변 추가
                                    </span>
                                </Button>

                            </div>

                        </div>
                        <div className={Styles.Body} ref={store.quickMsgStore.scrollRef} onScroll={store.quickMsgStore.onScrollHandler}>
                            <div className={Styles.ScrollContainer}>
                                <InfiniteScroll
                                    key={`infinite`}
                                    pageStart={0}
                                    loadMore={() => {
                                        store.quickMsgStore.loadMore()
                                    }}
                                    hasMore={store.quickMsgStore.hasMore}
                                    initialLoad
                                    useWindow={false}
                                    threshold={5}
                                    getScrollParent={() => store.quickMsgStore.scrollRef.current}
                                    loader={
                                        <div className={Styles.Loader} key={'loader'}>
                                            <Spinner animation="border" variant="secondary" size="sm"/>
                                        </div>
                                    }
                                >
                                    {
                                        store.quickMsgStore.quickMsgList.length > 0 ?
                                            store.quickMsgStore.quickMsgList.map((msg, idx) => {
                                            return (
                                                <OverlayTrigger
                                                    key={idx}
                                                    placement='top'
                                                    delay={{show: 250, hide: 250}}
                                                    overlay={(props)=>
                                                        <Popover style={{
                                                            maxWidth: '100%',
                                                            whiteSpace: "pre"
                                                        }} {...props}>
                                                            <Popover.Body>
                                                                {msg.content && msg.content}
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <div key={idx} className={Styles.MsgContainer}

                                                         ref={store.quickMsgStore.elmRefs[idx]}
                                                         style={{backgroundColor: store.quickMsgStore.cursor === idx ? "#EEF5F9" : "#FFFFFF"
                                                             , height:  msg.content.split("\n").length > 1 ? "90px" : "68px"
                                                            }}
                                                         onMouseEnter={() => this.mouseEnter(idx)}
                                                         onClick={() => {
                                                             store.quickMsgFn(msg.content);
                                                             store.quickMsgStore.setIsOpen(false);
                                                         }}
                                                    >
                                                        <div className={"d-flex flex-row"}>
                                                            <div className={Styles.MsgTitle}>
                                                                <ColoredItem item={msg.title} query={store.quickMsgStore.keyword}/>
                                                            </div>
                                                            <div className={"align-self-center ms-2"}>
                                                                <Badge bg={msg.quickMsgType === 'COMMON' ? "primary" : "secondary" }>
                                                                    {TYPE[msg.quickMsgType]}
                                                                </Badge>
                                                            </div>
                                                        </div>

                                                        <div className={Styles.MsgContent}>
                                                            <ColoredItem item={msg.content}
                                                                         query={store.quickMsgStore.keyword}/>
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>

                                            )
                                        })
                                        :
                                        <div className={Styles.NoMsg}>
                                            <div className={Styles.Title}>
                                                등록된 빠른 답변이 없습니다.
                                            </div>
                                            <div className={Styles.Description}>
                                                <p className={'mb-0'}>등록된 빠른 답변이 없습니다.</p>
                                                <p>아래 버튼을 눌러 빠른 답변을 등록 해 주세요.</p>
                                            </div>
                                            <Button variant={'outline-primary'}
                                                    onClick={() => {
                                                        store.quickMsgFn("")
                                                        store.quickMsgStore.setIsPopupOpen(true);
                                                    }}
                                            >
                                                <MessageAdd
                                                    size="24"
                                                />
                                                <span className={'ms-2'}>빠른답변 등록</span>
                                            </Button>
                                        </div>
                                    }

                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                }
            </>

        );
    }
}

export default QuickMsg;