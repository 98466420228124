import React from 'react';
import Styles from './OutgoingMessage.module.scss'
import {Spinner} from "react-bootstrap";
import moment from "moment";
import {Button} from "react-bootstrap";
import {observer} from "mobx-react";
import {ReplyMessage} from "./ReplyMessage.js";
import {ArrowRotateRight, Back, CloseCircle} from "iconsax-react";
import Message from "./Message.js";
import MessageContent from "./MessageContent.js";

@observer
class OutgoingMessage extends React.Component {

    replyMessage(message){
        let {store} = this.props;
        store.replyMessage = message
    }

    render() {
        let {message, store} = this.props;
        let { createdAt, isSent = true, isSending = false, focus = false, notConfirmCount = 0, replyMessage, content} = message;
        return (
            <div className={`${Styles.Container} ${focus ? Styles.Focus : ''}`}
                 onAnimationEnd={()=>{
                     store.messageMap.set(message.id, {...message, focus: false})
                 }}
            >
                <div className={Styles.Content}>
                    <div className={`d-flex`}>
                        <div className={Styles.MessageOption}>
                            {
                                !isSending && isSent &&
                                    <Button variant={"secondary"} size={'sm'} className={Styles.Button}>
                                        <Back className={Styles.Icon} onClick={()=>{this.replyMessage(message)}}/>
                                    </Button>
                            }
                            <div className={Styles.Resending}>
                                {
                                    isSending ?
                                        <Spinner animation="border" variant="secondary" size="sm"/>
                                        :
                                        !isSent ?
                                            <Button variant={"secondary"} size={'sm'} className={Styles.ResendButton}
                                                    onClick={()=>{
                                                        store.openResendDialog(message);
                                                    }}
                                            >
                                                <ArrowRotateRight/><CloseCircle color={`var(--bs-danger)`}/>
                                            </Button>
                                            :
                                            <>
                                                {
                                                    notConfirmCount > 0 &&
                                                        <div className={Styles.NotConfirmCount}>
                                                            {notConfirmCount}
                                                        </div>
                                                        // :
                                                        // <MdDoneAll color={'#009688'}/>
                                                }
                                            </>

                                }
                            </div>
                        </div>
                        <Message isIncoming={false} store={store}>
                            <MessageContent content={content}
                                            messageKey={message.id}
                                            store={store}
                                            isIncoming={false}
                                            replyMessage={replyMessage}
                            />
                        </Message>
                    </div>
                    <div className={Styles.Date}>
                        {moment(createdAt).format('LT')}
                    </div>
                </div>

            </div>
        )
    }
}

export default OutgoingMessage;
