import React from 'react';
import {observer} from "mobx-react";
import {Nav, Tab, TabContent} from 'react-bootstrap';
import Styles from './ScrollTab.module.scss'

@observer
class ScrollTabNavItem extends React.Component{

    constructor(props) {
        super(props);
    }



    render() {
        let {isActive, onClick, eventKey, children, isBlink = false} = this.props;
        return(
            <div className={`${Styles.NavItem} ${isActive ? Styles.On : ''} ${!isActive && isBlink ? Styles.Blink : ''}`}
                 onClick={()=>{
                     onClick && onClick(eventKey)
                 }}
            >
                {children}
            </div>
        )

    }
}

ScrollTabNavItem.propType = {
}

export {ScrollTabNavItem};
