import {CHAT_CONTEXT_PATH, CHAT_WS_URL} from '../const/AppConst';
import {ChannelStore, ChatStore, RSocket, Sort, TicketStore, QuickMsgStore} from '@qbot-chat/qbot-uikit';
import {navigate} from "../helper/HistoryHelper";
import {
    TICKET_STATUS, ICON_TYPE,
    BUTTON_GROUP_TYPE,
    BUTTON_TYPE,
    CONTENT_TYPE,
    MESSAGE_TYPE
} from "@qbot-chat/qbot-uikit/chat";


const rSocket = new RSocket(
    CHAT_WS_URL,
    CHAT_CONTEXT_PATH
);

rSocket.setNotificationHandler((type,message)=>{

})

window.rSocket = rSocket;
const chatStore = new ChatStore();
chatStore.setInnerLinkFn(navigate);

const ticketStore = new TicketStore();
ticketStore.setGreetingMessage(
    {
        type: MESSAGE_TYPE.GREETING,
        content: {
            isBubble: false,
            type: CONTENT_TYPE.CUSTOM,
            value: [
                {
                    type: CONTENT_TYPE.BUTTON,
                    value: {
                        type: BUTTON_GROUP_TYPE.GRID,
                        buttons: [
                            {
                                label: '상품 검색',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://naver.com`,
                                icon: ICON_TYPE.SEARCH_PRODUCT
                            },
                            {
                                label: 'e머니',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://emartapp.emart.com/emoney/start.do`,
                                icon: ICON_TYPE.E_MONEY
                            },
                            {
                                label: '상품권',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://naver.com`,
                                icon: ICON_TYPE.GIFT_CARD
                            },
                            {
                                label: '상품권 사용조회',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://store.emart.com/event/detail/checkEmartGiftCard.do`,
                                icon: ICON_TYPE.GIFT_USED
                            },
                            {
                                label: '점포시설/휴점일',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://emartapp.emart.com/branch/main.do?mallGbn=E`,
                                icon: ICON_TYPE.HOLIDAY
                            },
                            {
                                label: '전단/신문광고',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://emartapp.emart.com/onlineLeaflet/list.do`,
                                icon: ICON_TYPE.COUPON
                            },
                            {
                                label: '교환/환불',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://emartapp.emart.com/service/service_view.do?type=3`,
                                icon: ICON_TYPE.REFUND
                            },
                            {
                                label: '택배 조회',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://emartapp.emart.com/parcel/parcelTraceMain.do`,
                                icon: ICON_TYPE.DELIVERY
                            },
                            {
                                label: '선물세트',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://emartapp.emart.com/catalog/main.do?trcknCode=main_banner`,
                                icon: ICON_TYPE.GIFT_SET
                            },
                            {
                                label: '이벤트',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://store.emart.com/news/event/progress_list.do?trcknCode=header_event`,
                                icon: ICON_TYPE.EVENT
                            },
                            {
                                label: '문화센터',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `http://culture.emart.com/main;jsessionid=3YMLms3mxOuwaqxekV9hgsvKwwaSTkDkg0qQHYSQVbDg3Tzi7iC11URs7DgZ5gHa.emccwas2_servlet_engine12`,
                                icon: ICON_TYPE.CULTURE_CENTER
                            },
                            {
                                label: '쓱고우',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://ondemandapp.page.link/Yp7j`,
                                icon: ICON_TYPE.SSG_GO
                            },
                            {
                                label: '테넌트맵',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://e-compass-client.emart.com/find/store/1042/map`,
                                icon: ICON_TYPE.TENENT
                            },
                            {
                                label: '트레이더스 소개',
                                type: BUTTON_TYPE.OUTER_LINK,
                                value: `https://eapp.emart.com/traders/info.do?trcknCode=main_banner_TR&mallGbn=T`,
                                icon: ICON_TYPE.TRADERS_INTRO
                            },
                        ]
                    },
                },
                {
                    type: CONTENT_TYPE.TEXT,
                    value: `오미크론 확산에 따른 2월 단축영업 안내${'\n'}시간 : 오전 10시~ 오후 10시${'\n'}예외 : 사천점 오전 11시~ 오후10시`,
                }
            ]
        },
        contentType: CONTENT_TYPE.CUSTOM,
    }
);
const quickMsgStore = new QuickMsgStore();

let channelStore = new ChannelStore({rowsPerPage: 10,
    paginationSize:3,
    sort: new Sort('createdAt', Sort.SORT_DIRECTION.DESC)
});

export {rSocket, chatStore, channelStore, ticketStore, quickMsgStore};
