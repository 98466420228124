import React from 'react';
import {observer} from "mobx-react";
import { FaStar } from 'react-icons/fa';
import PropTypes from "prop-types";
import Styles from './Rating.module.scss';
import {Button, FormControl} from "react-bootstrap";
import {ChatStore} from "../../store/ChatStore.js";


@observer
class Rating extends React.Component {

    componentDidMount() {
    }

    constructor(props) {
        super(props);

        this.state = {
            rating : 5,
            comment : ''
        };
    }

    ratings =
        [
            {value:0, label:'매우불만'},
            {value:1, label:'불만'},
            {value:2, label:'보통'},
            {value:3, label:'만족'},
            {value:4, label:'매우만족'}
        ]

    render() {
        let {onClosed, store, message=`새롭게 도입한 디지털 상담 시스템(챗봇/채팅)은 보다 많은 고객들의 문의를 신속하게 처리하기 위함입니다.${'\n'}고객님의 만족도는 몇 점입니까?`} = this.props;
        return(
            <div className={Styles.Container}>
                <div className={Styles.Content}>
                    <div className={Styles.Label}>
                        {message}
                    </div>


                    <div className={Styles.RatingWrap}>
                        {
                            this.ratings
                                .map(rating =>
                                    <div key={`rating_${rating.value}`} onClick={ () => {
                                        this.setState({rating:rating.value+1})
                                    }

                                    }
                                         className={Styles.Rating}
                                    >
                                        <div className={`${Styles.StarIcon} ${this.state.rating > rating.value ? Styles.Active:''}`}>
                                            <FaStar />
                                        </div>
                                        <div className={Styles.RatingLabel}>
                                            {rating.label}
                                        </div>

                                    </div>
                                )
                        }
                    </div>

                    <hr />

                    <div className={Styles.CommentWrap}>
                        <div className={Styles.Label}>
                            <p>자유롭게 의견을 남겨주세요.</p>
                        </div>

                        <FormControl
                            className={Styles.TextArea}
                            as={'textarea'}
                            placeholder={' - 여기에 자유롭게 입력해주세요. \n - 입력하지 않으셔도 설문을 완료할 수 있습니다. \n - 1000자 이내로 작성하실 수 있습니다.'}
                            rows={5} maxLength={1000}
                            onChange={(event) => this.setState({comment : event.target.value})}
                        />
                    </div>

                    <Button style={{
                        width:'100%',
                        fontWeight:'bold', padding:'0.75rem 0', borderRadius:0 ,
                        backgroundColor:'#FFE926', border:'#FFE926', color:'var(--bs-gray-900)'
                    }}
                            onClick={()=>{
                                store.ticketRating(this.state.rating, this.state.comment)
                                    .then( r => onClosed && onClosed())
                            }}
                    >
                        설문제출
                    </Button>
                </div>
            </div>
        )
    }
}
Rating.defaultProps = {
};

Rating.propTypes = {
    store: PropTypes.instanceOf(ChatStore)
}


export {Rating};